import React from 'react';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import styled from 'styled-components';

import { error } from '@/services/notification';
import { DOWNLOAD_FILE, UPLOAD_FILE } from '@/constants/api';
import { RootState } from '@/store/configureStore';
import { UPLOAD_FILE_FAILED, UPLOAD_FILE_REACT_SIZE_LIMIT } from '@/constants/strings';
import { MAX_UPLOAD_FILE_SIZE } from '@/constants/form';

type File = {
  uid: string;
  name: string;
  status: Status;
  url: string;
};

interface UploadDocumentsProps {
  value?: File[];
  onChange?: (value: File[]) => void;
}

enum Status {
  DONE = 'done',
  ERROR = 'error',
  SUCCESS = 'success',
  UPLOADING = 'uploading',
  REMOVED = 'removed',
}

const AntdUpload = styled(Upload)`
  .ant-upload-list {
    max-width: 400px;
  }
`;

const UploadDocuments: React.FC<UploadDocumentsProps> = ({ value, onChange }) => {
  const defaultFileList = value ?? [];

  const currentFormId = useSelector((state: RootState) => state.form.detail.data?._id);

  const beforeUpload = (file: RcFile) => {
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      error(UPLOAD_FILE_REACT_SIZE_LIMIT);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const onRemove = (file: UploadFile) => {
    if (value) {
      const newValue = value.filter((el) => el.uid !== (file.response?.data._id || file.uid));
      onChange?.(newValue);
    }
    return true;
  };

  const onFileChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    if (file.status === Status.DONE) {
      const newValue = fileList.map((file) => {
        if (file.response) {
          return {
            uid: file.response.data._id,
            name: file.response.data.name,
            status: 'done',
            url: `/api${DOWNLOAD_FILE(file.response.data._id)}`,
          } as File;
        }
        return file as File;
      });
      onChange?.(newValue);
    } else if (file.status === Status.ERROR) {
      error(UPLOAD_FILE_FAILED);
    }
  };

  return (
    <AntdUpload
      defaultFileList={defaultFileList}
      action={`/api${UPLOAD_FILE}`}
      beforeUpload={beforeUpload}
      onRemove={onRemove}
      data={{ form: currentFormId }}
      onChange={onFileChange}
    >
      <Button icon={<UploadOutlined />}>Upload (Maximum file size: 2MB)</Button>
    </AntdUpload>
  );
};

export default UploadDocuments;
