import React, { FC, useEffect, useState, useContext } from 'react';
import { FullContainer } from '@/styles/global';
import { AppstoreOutlined, PlusOutlined } from '@ant-design/icons';
import ModuleHeader from '@Components/layout/module-header';
import { HEADER_ICON } from '@Constants/ui';
import { ModuleBody } from '@Components/layout/module-body';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/configureStore';
import { createStudy, deleteStudy, getStudies, updateStudy } from '@/store/slices/study';
import StudyTable, { StudiesTableProps } from './table';
import { Maybe, StudyDTO } from '@/interfaces';
import { Link, useHistory } from 'react-router-dom';
import StudyFormWithModal from '@Components/forms/study';
import { success } from '@/services/notification';
import { DATA_SAVE } from '@Constants/strings';
import { Breadcrumb } from 'antd';
import { USER_ACTIONS, USER_ACTION_SUBJECTS } from '@/services/permission';
import { PermissionContext } from '@/contexts/permission';

const StudyList: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const studies = useSelector((state: RootState) => state.study.list.data);
  const permission = useContext(PermissionContext);

  const [showStudyForm, setShowStudyForm] = useState(false);
  const [studyForm, setStudyForm] = useState<Maybe<StudyDTO>>();

  const onSubmitStudy = (body: StudyDTO) => {
    setShowStudyForm(false);
    setStudyForm(undefined);
    const isEdit = !!studyForm;
    if (!isEdit) {
      dispatch(createStudy(body)).then(({ payload }) => {
        payload && payload.data && success(DATA_SAVE);
      });
    } else {
      dispatch(updateStudy({ ...studyForm, ...body })).then(({ payload }) => {
        payload && payload.data && success(DATA_SAVE);
      });
    }
  };

  const onOpenStudyModal = () => {
    setShowStudyForm(true);
  };

  const onCloseStudyModal = () => {
    setShowStudyForm(false);
    setStudyForm(undefined);
  };

  const onDeleteStudy = (study: StudyDTO) => {
    dispatch(deleteStudy(study._id)).then(({ payload }) => {
      payload && payload.data && success(DATA_SAVE);
    });
  };

  const onOpenStudy = (study: StudyDTO) => {
    history.push(`/studies/${study._id}`);
  };

  const onEditStudy = (study: StudyDTO) => {
    setShowStudyForm(true);
    setStudyForm(study);
  };

  const renderHeader = () => {
    const actions = [];

    if (permission?.can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.STUDY)) {
      actions.push({
        label: 'Create Study',
        isBadge: false,
        onClick: onOpenStudyModal,
        icon: <PlusOutlined />,
      });
    }
    const title = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/studies'}>Studies</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
    return (
      <ModuleHeader
        actions={actions}
        title={title}
        icon={<AppstoreOutlined style={HEADER_ICON} />}
      />
    );
  };

  const renderBody = () => {
    let studyListProps: StudiesTableProps = {
      studies,
    };

    if (permission?.can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.STUDY)) {
      studyListProps.onOpenStudy = onOpenStudy;
    }

    if (permission?.can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.STUDY)) {
      studyListProps.onEditStudy = onEditStudy;
    }

    if (permission?.can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.STUDY)) {
      studyListProps.onDeleteStudy = onDeleteStudy;
    }

    return (
      <ModuleBody>
        <StudyTable {...studyListProps} />
      </ModuleBody>
    );
  };

  const renderModals = () => {
    return (
      <StudyFormWithModal
        onSubmit={onSubmitStudy}
        onClose={onCloseStudyModal}
        formName={'StudyForm'}
        title={studyForm ? 'Edit Study' : 'Create Study'}
        okText={'Submit'}
        show={showStudyForm}
        initialValues={studyForm}
        isEdit={!!studyForm}
      />
    );
  };

  useEffect(() => {
    dispatch(getStudies());
  }, []);

  return (
    <FullContainer direction={'column'} overflow={'hidden'}>
      {renderHeader()}
      {renderBody()}
      {renderModals()}
    </FullContainer>
  );
};

export default StudyList;
