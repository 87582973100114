import { FormFieldTypes, FormField, DescriptionTypes } from '../dynamic-form-fields';
import { OPTIONS } from './cioms-options';
import { COUNTRIES } from '@Constants/options';

const CIOMS_FORM_FIELDS: FormField[] = [
  {
    label: 'Reaction Information',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Patient Initials (first, last)',
    name: 'patient',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Country',
    name: 'country',
    type: FormFieldTypes.SELECT,
    options: COUNTRIES,
  },
  {
    label: 'Date of Birth',
    name: 'dateOfBirth',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Age (Years)',
    name: 'age',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Sex',
    name: 'sex',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Reaction Onset',
    name: 'reactionOnset',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Describe Reaction(s) (including relevant tests/lab data)',
    name: 'describeReactions',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label: 'Check all appropriate to adverse reaction',
    name: 'adverseReaction',
    type: FormFieldTypes.CHECK_BOX,
    options: OPTIONS.adverseReaction,
  },
  {
    label: 'Suspect drug(s) information',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Suspect drug(s) (include generic name)',
    name: 'suspectDrugs',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Daily dose(s)',
    name: 'dailyDoses',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Route(s) of administration',
    name: 'routesOfAdministration',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Indication(s) for use',
    name: 'indicationsForUse',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label: 'Therapy Dates (from)',
    name: ['therapyDate', 0],
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Therapy Dates (to)',
    name: ['therapyDate', 1],
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Therapy duration',
    name: 'therapyDuration',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Did reaction abate after stopping drug?',
    name: 'reactionAbate',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.reactionAbate,
  },
  {
    label: 'Did reaction reappear after reintroduction?',
    name: 'reactionReappear',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.reactionReappear,
  },
  {
    label: 'Concomitant drug(s) and history',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Concomitant drug(s) and dates of administration (exclude those used to treat reaction)',
    name: 'concomitantDrugs',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label:
      'Other relevant history (e.g. diagnostics, allergics, pregancy with last month of period, etc.)',
    name: 'releventHistory',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label: 'Manufacturer information',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Name and address of manufacturer',
    name: 'manufacturer',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'MFR control No.',
    name: 'mrfNo',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date received by manufacturer',
    name: 'dateOfManufacturer',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Report source',
    name: 'reportSource',
    type: FormFieldTypes.CHECK_BOX,
    options: OPTIONS.reportSource,
  },
  {
    label: 'Date of this report',
    name: 'dateReport',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Report type',
    name: 'reportType',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.reportType,
  },
  {
    label: 'Record follow-up report detail',
    addOrRemoveText: 'Follow Up Detail',
    name: 'followUpDetails',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Follow-up Number',
        name: 'followUpNumber',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Data entry completed by',
        name: 'followUpDataEntry',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'QC entry completed by',
        name: 'followUpQcEntry',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Medical Review completed by',
        name: 'followUpMedicalReview',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: 'Name and Address of PI',
    name: 'nameAndAddressOfPI',
    type: FormFieldTypes.INPUT,
  },
];

export { CIOMS_FORM_FIELDS };
