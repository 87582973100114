import { AbilityBuilder, Ability, Subject } from '@casl/ability';
import type { AccountState } from '@/store/slices/account';
import * as Models from './models';
import { USER_ROLES } from '@/interfaces';

enum USER_ACTIONS {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  EXPORT = 'EXPORT',
}

enum USER_ACTION_SUBJECTS {
  USERS = 'Users',
  USER = 'User',
  FORMS = 'Forms',
  FORM = 'Form',
  STUDY = 'Study',
}

type Actions = `${USER_ACTIONS}`;
export type Abilities =
  | [USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USERS]
  | [Exclude<Actions, `${USER_ACTIONS.EXPORT}`>, USER_ACTION_SUBJECTS.USER]
  | [USER_ACTIONS.UPDATE, Models.User]
  | [USER_ACTIONS.DELETE, Models.User]
  | [USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORMS]
  | [Actions, USER_ACTION_SUBJECTS.FORM]
  | [Exclude<Actions, `${USER_ACTIONS.EXPORT}`>, USER_ACTION_SUBJECTS.STUDY];
export type AppAbility = Ability<Abilities>;

const definePermissionsFor = (account: AccountState): AppAbility => {
  const { can, cannot, build } = new AbilityBuilder<Ability<Abilities>>(Ability);

  if (account.isAdmin) {
    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USERS);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USER);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER);
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'email');
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'role', { id: account.id });
    can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.USER);
    cannot(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.USER, { id: account.id });
    can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.USER);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORMS);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.EXPORT, USER_ACTION_SUBJECTS.FORM);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.STUDY);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.STUDY);
    can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.STUDY);
  } else if (account.isStudyAdmin) {
    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USERS);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USER);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER);
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, { role: USER_ROLES.ADMIN });
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'email');
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'role', { id: account.id });
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'associatedStudies');
    can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.USER);
    cannot(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.USER, { role: USER_ROLES.ADMIN });
    cannot(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.USER, { id: account.id });
    can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.USER);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORMS);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.EXPORT, USER_ACTION_SUBJECTS.FORM);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.STUDY);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.STUDY);
    can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.STUDY);
  } else if (account.isMonitor) {
    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USER);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, { id: account.id });
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'email', { id: account.id });
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'role', { id: account.id });
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'firstName', { id: account.id });
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'lastName', { id: account.id });
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'password', { id: account.id });

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORMS);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.FORM);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.STUDY);
  } else if (account.isUser) {
    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USER);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, { id: account.id });
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'email', { id: account.id });
    cannot(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'role', { id: account.id });
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'firstName', { id: account.id });
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'lastName', { id: account.id });
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.USER, 'password', { id: account.id });

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORMS);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORM);
    can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.FORM);

    can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.STUDY);
  }

  return build() as AppAbility;
};

export { USER_ACTIONS, USER_ACTION_SUBJECTS, definePermissionsFor, Models };
