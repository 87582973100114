import React, { FC, useEffect, useRef } from 'react';
import { FullContainer } from '../../styles/global';
import { Input, Space, Button, Typography, Form } from 'antd';
import { useSendResetEmail } from '@/hooks/useSendResetEmail';
import notification from '@/services/notification';

const { Title, Text } = Typography;

const PasswordRecovery: FC = () => {
  const [form] = Form.useForm();
  const { loading, error, data, request } = useSendResetEmail();

  useEffect(() => {
    if (error) {
      notification.error('Failed to send reset email.');
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      notification.success('Reset email sent, please check your inbox.');
    }
  }, [data]);
  return (
    <FullContainer alignItems="center" justifyContent="center">
      <Space direction="vertical">
        <Title level={4}>Forgot Password?</Title>
        <Text>
          Enter the email address you used when you joined and we’ll send you instructions to reset
          your password.
        </Text>
        <Form form={form} name="send-reset-email" onFinish={request} disabled={loading}>
          <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </FullContainer>
  );
};

export default PasswordRecovery;
