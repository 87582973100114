import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/configureStore';
import PublicRoutes from './routes/public-routes';
import AuthRoutes from './routes/auth-routes';
import AuthRouteWrapper from './components/auth-route-wrapper';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/(login|recovery|reset-password|register|register-homepage|verify-email|activate-account)">
            <PublicRoutes />
          </Route>
          <AuthRouteWrapper path="*" component={AuthRoutes} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
