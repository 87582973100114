import styled from 'styled-components';
import { Button } from 'antd';

export const LockButton = styled(Button)`
  color: #faad14 !important;
  border-color: #faad14 !important;
`;

export const UnlockButton = styled(Button)`
  color: #52c41a !important;
  border-color: #52c41a !important;
`;
