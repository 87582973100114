export const OPTIONS = {
  reportType: [
    {
      label: 'Initial',
      value: 'INITIAL',
    },
    {
      label: 'Follow-up',
      value: 'FOLLOW_UP',
    },
  ],
  subjectSex: [
    {
      label: 'Male',
      value: 'MALE',
    },
    {
      label: 'Female',
      value: 'FEMALE',
    },
  ],
  attentionReason: [
    {
      label: 'Pregnancy of Subject',
      value: 'PREGNANCY_OF_SUBJECT',
    },
    {
      label:
        "Pregnancy of Subject's Partner (Complete section of “Female Partner Details” in the below section)",
      shortLabel: "Pregnancy of Subject's Partner",
      value: 'PREGNANCY_OF_SUBJECT_PARTNER',
    },
    {
      label: 'Suspected Pregnancy',
      value: 'SUSPECTED_PREGNANCY',
    },
    {
      label: 'Exposure of Non-Subject Pregnancy Female',
      value: 'EXPOSURE_OF_NON_SUBJECT_PREGNANCY_FEMALE',
    },
  ],
  pregnancyTestResultNegative: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  pregnancyInitiallyDiagnosedBy: [
    {
      label: 'Office Urine Test',
      value: 'OFFICE_URINE_TEST',
    },
    {
      label: 'Serum Test',
      value: 'SERUM_TEST',
    },
  ],
  femaleCurrentlyPregnant: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  historyOfCongenitalAnomalies: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  historyOfFamilialCongenitalAnomalies: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  historyOfStillbirths: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  methodOfBirthControl: [
    {
      label: 'Abstinence',
      value: 'ABSTINENCE',
    },
    {
      label: 'Condom',
      value: 'CONDOM',
    },
    {
      label: 'Diaphragm',
      value: 'DIAPHRAM',
    },
    {
      label: 'Depot contraceptive',
      value: 'DEPOT_CONTRACEPTIVE',
    },
    {
      label: 'Intrauterine Device (IUD)',
      value: 'INTRAUTERINE_DEVICE',
    },
    {
      label: 'Oral Contraceptive',
      value: 'ORAL_CONTRACEPTIVE',
    },
    {
      label: 'Rhythm Method',
      value: 'RHYTHM_METHOD',
    },
    {
      label: 'Spermicide',
      value: 'SPERMICIDE',
    },
    {
      label: 'Sterilization (female)',
      value: 'STERILIZATION_FEMALE',
    },
    {
      label: 'Sterilization (male)',
      value: 'STERILIZATION_MALE',
    },
    {
      label: 'None',
      value: 'NONE',
    },
    {
      label: 'Other',
      value: 'OTHER',
    },
  ],
  femaleElection: [
    {
      label: 'Terminate Pregnancy (Date Performed or Pending)',
      value: 'TERMINATE_PREGNANCY',
    },
    {
      label: 'Carry Pregnancy to Term (Expected Date of Delivery)',
      value: 'CARRY_PREGNANCY_TO_TERM',
    },
  ],
  infantOrFetusSex: [
    {
      label: 'Male',
      value: 'MALE',
    },
    {
      label: 'Female',
      value: 'FEMALE',
    },
  ],
  infantOrFetusOutcome: [
    {
      label: 'Term birth/healthy infant',
      value: 'TERM_INFANT',
    },
    {
      label: 'Elective Termination',
      value: 'ELECTIVE_TERMINATION',
    },
    {
      label: 'Premature birth/congenital anomaly',
      value: 'PREMATURE_ANOMALY',
    },
    {
      label: 'Premature birth/healthy infant',
      value: 'PREMATURE_INFANT',
    },
    {
      label: 'Term birth/congenital anomaly',
      value: 'TERM_ANOMALY',
    },
    {
      label: 'Spontaneous abortion',
      value: 'SPONTANEOUS_ABORTION',
    },
    {
      label: 'Stillbirth',
      value: 'STILLBIRTH',
    },
    {
      label: 'Unknown/Lost to follow-up',
      value: 'LOST_TO_FOLLOW_UP',
    },
  ],
  infantOrFetusMethodOfDelivery: [
    {
      label: 'Vaginal',
      value: 'VAGINAL',
    },
    {
      label: 'C-section',
      value: 'C_SECTION',
    },
    {
      label: 'Forceps/Vacuum extraction',
      value: 'FORCEPS_EXTRACTION',
    },
  ],
  infantOrFetusAutopsyPerformed: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  infantOrFetusCongenitalAnomalies: [
    {
      label: 'Yes',
      shortLabel: 'YES',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  impactAssessmentSafetyIssue: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  impactAssessmentSafetyMeasure: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  phase: [
    {
      label: 'Phase I',
      value: 'I',
    },
    {
      label: 'Phase I/IIa',
      value: 'I/IIa',
    },
    {
      label: 'Phase II',
      value: 'II',
    },
    {
      label: 'Phase III',
      value: 'III',
    },
    {
      label: 'Phase IV',
      value: 'IV',
    },
  ],
  blindingStatus: [
    {
      label: 'Blinded',
      value: 'BLINDED',
    },
    {
      label: 'Open-label',
      value: 'OPEN_LABEL',
    },
  ],
  caseSummaryIsCaseSerious: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  race: [
    {
      label: 'American Indian or Alaska Native',
      value: 'NATIVE_INDIAN_OR_ALASKAN',
    },
    {
      label: 'Asian',
      value: 'ASIAN',
    },
    {
      label: 'Black or African American',
      value: 'BLACK_OR_AFRICAN_AMERICAN',
    },
    {
      label: 'Hawaiian or Pacific Islanders',
      value: 'HAWAIIAN_OR_PACIFIC_ISLANDERS',
    },
    {
      label: 'White',
      value: 'WHITE',
    },
    {
      label: 'Other',
      value: 'OTHER',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
    {
      label: 'Not reported',
      value: 'NOT_REPORTED',
    },
  ],
  ethnicity: [
    {
      label: 'Hispanic or Latino',
      value: 'HISPANIC_OR_LATINO',
    },
    {
      label: 'Not Hispanic or Latino',
      value: 'NOT_HISPANIC_OR_LATINO',
    },
    {
      label: 'Not Reported',
      value: 'NOT_REPORTED',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
  ],
  dose: [
    {
      label: 'MBq/kg',
      value: 'MBq/kg',
    },
    {
      label: 'ML',
      value: 'ML',
    },
    {
      label: 'Microgram',
      value: 'Microgram',
    },
    {
      label: 'Gram',
      value: 'Gram',
    },
  ],
};
