import axios, { ResponseType } from 'axios';
import notification from '@/services/notification';

const instance = axios.create({
  baseURL: '/api',
});

const CLIENT = {
  delete: (url: string) => {
    const opts = {
      headers: { 'Content-Type': 'application/json' },
      method: 'delete',
      url: url,
      withCredentials: true,
    };
    return instance.request(opts).catch((e) => {
      notification.error(e.response?.data?.message || e.message);
      throw e;
    });
  },
  get: (url: string, params?: object) => {
    const opts = {
      headers: { 'Content-Type': 'application/json' },
      method: 'get',
      params: params || undefined,
      url: url,
      withCredentials: true,
    };
    return instance.request(opts).catch((e) => {
      notification.error(e.response?.data?.message || e.message);
      throw e;
    });
  },
  post: function (url: string, data?: object, params?: object) {
    const opts = {
      data: data,
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      params: params || undefined,
      url: url,
      withCredentials: true,
    };
    return instance.request(opts).catch((e) => {
      notification.error(e.response?.data?.message || e.message);
      throw e;
    });
  },
  patch: function (url: string, data: object, params?: object) {
    const opts = {
      data: data,
      headers: { 'Content-Type': 'application/json' },
      method: 'patch',
      params: params || undefined,
      url: url,
      withCredentials: true,
    };
    return instance.request(opts).catch((e) => {
      notification.error(e.response?.data?.message || e.message);
      throw e;
    });
  },
  download: function (url: string) {
    const opts = {
      method: 'get',
      url: url,
      withCredentials: true,
      responseType: 'blob' as ResponseType,
    };
    return instance.request(opts).catch((e) => {
      notification.error(e.response?.data?.message || e.message);
      throw e;
    });
  },
};

export default CLIENT;
