import { FormFieldTypes, FormField, DescriptionTypes } from '../dynamic-form-fields';
import { OPTIONS } from './pregency-options';
import { COUNTRIES } from '@Constants/options';

const PREGNANCY_FORM_FIELDS: FormField[] = [
  {
    label: '1. General Information',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Protocol Number',
    name: 'protocolNumber',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Protocol Title',
    name: 'protocolTitle',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Sponsor Name and Address',
    name: 'nameAndAddressOfSponsor',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Report type',
    name: 'reportType',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.reportType,
  },
  {
    label: 'Record follow-up report detail',
    addOrRemoveText: 'Follow Up Detail',
    name: 'followUps',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Follow-up Number',
        name: 'followUpNumber',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Follow-up Date',
        name: 'followUpDate',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: 'Date of sending the initial report',
    name: 'sendingDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date of site awareness of Initial report',
    name: 'siteAwarenessDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date of awareness of Follow-up report (NA for Initial reports)',
    name: 'sendingDateFollowUp',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Safety Received Date',
    name: 'safetyReceivedDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Received Time',
    name: 'safetyReceivedTime',
    type: FormFieldTypes.TIME_PICKER,
  },
  {
    label: 'Tracked Case Number',
    name: 'trackedCaseNumber',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Study Phase',
    name: 'studyPhase',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.phase,
  },
  {
    label: 'Blinding Status',
    name: 'blindingStatus',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.blindingStatus,
  },
  {
    label: '2.a. Subject details',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Country',
    name: 'country',
    type: FormFieldTypes.SELECT,
    options: COUNTRIES,
  },
  {
    label: 'Subject’s age at the time of pregnancy onset (Years)',
    name: 'age',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Age group',
    name: 'ageGroup',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Height(cm)',
    name: 'height',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Weight(kg)',
    name: 'subjectWeight',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Sex of subject',
    name: 'subjectSex',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.subjectSex,
  },
  {
    label: 'Attention’s reason',
    name: 'attentionReason',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.attentionReason,
  },
  {
    label: 'Subject’s Race',
    name: 'subjectRace',
    type: FormFieldTypes.SELECT,
    options: OPTIONS.race,
  },
  {
    label: 'Other race (not listed) of Subject’s Race',
    name: 'otherSubjectRace',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Subject’s Ethnicity',
    name: 'subjectEthnicity',
    type: FormFieldTypes.SELECT,
    options: OPTIONS.ethnicity,
  },
  {
    label: '2.b. Female Partner details',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Age group',
    name: 'femaleAgeGroup',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Age at estimated date of conception (Years)',
    name: 'femaleAge',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Race',
    name: 'femaleRace',
    type: FormFieldTypes.SELECT,
    options: OPTIONS.race,
  },
  {
    label: 'Other race (not listed) of Subject’s Race',
    name: 'otherFemaleRace',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Ethnicity',
    name: 'femaleEthnicity',
    type: FormFieldTypes.SELECT,
    options: OPTIONS.ethnicity,
  },
  {
    label: '3.	Maternal Information',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Event Term',
    name: 'eventTerm',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'LLT',
    name: 'maternalInfoLLT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'PT',
    name: 'maternalInfoPT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'HLT',
    name: 'maternalInfoHLT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'HLGT',
    name: 'maternalInfoHLGT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'SOC',
    name: 'maternalInfoSOC',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date of pregnancy diagnosis',
    name: 'dateOfPregnancyDiagnosis',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Screening Pregnancy Test',
    name: 'screeningPregnancyTest',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date of Pregnancy Test',
    name: 'dateOfPregnancyTest',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Pregnancy Test Result (Reference Range)',
    name: 'pregnancyTestResultReferenceRange',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Negative?',
    name: 'pregnancyTestResultNegative',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.pregnancyTestResultNegative,
  },
  {
    label: 'Last Menstrual Period',
    name: 'lastMenstrualPeriod',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Pregnancy Initially Diagnosed By',
    name: 'pregnancyInitiallyDiagnosedBy',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.pregnancyInitiallyDiagnosedBy,
  },
  {
    label: 'Estimated delivery date',
    name: 'estimatedDeliveryDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Female is currently pregnant?',
    name: 'femaleCurrentlyPregnant',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.femaleCurrentlyPregnant,
  },
  {
    label: 'If Yes, pregnancy weeks',
    name: 'pregnancyWeeks',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Total # prior pregnancies',
    name: 'totalPriorPregnancies',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Total # prior live births',
    name: 'totalPriorLiveBirths',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Total # terminations (spontaneous and elective)',
    name: 'totalTerminations',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'History of congenital anomalies with prior pregnancies?',
    name: 'historyOfCongenitalAnomalies',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.historyOfCongenitalAnomalies,
  },
  {
    label: 'History of familial congenital anomalies?',
    name: 'historyOfFamilialCongenitalAnomalies',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.historyOfFamilialCongenitalAnomalies,
  },
  {
    label: 'History of stillbirths?',
    name: 'historyOfStillbirths',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.historyOfStillbirths,
  },
  {
    label: 'Method of Birth Control',
    name: 'methodOfBirthControl',
    type: FormFieldTypes.CHECK_BOX,
    options: OPTIONS.methodOfBirthControl,
  },
  {
    label: 'Other method (not listed) of Birth Control',
    name: 'otherMethodOfBirthControl',
    type: FormFieldTypes.INPUT,
  },
  {
    label: '4. Concomitant Medications',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label:
      'Add concomitant Medications at time of study entry, during study treatment and at conception ',
    addOrRemoveText: 'concomitant medication',
    name: 'concomitantMedications',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Name of medication',
        name: 'nameOfMedication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Indication',
        name: 'indication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Dose with units',
        name: 'doseWithUnits',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Dosage form',
        name: 'dosageForm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Route',
        name: 'route',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Frequency',
        name: 'frequency',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Start date',
        name: 'startDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'End date*',
        name: 'endDate',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '5.	Maternal History',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Add maternal history',
    addOrRemoveText: 'maternal history',
    name: 'maternalHistory',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'LLT',
        name: 'maternalHistoryLLT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'PT',
        name: 'maternalHistoryPT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'HLT',
        name: 'maternalHistoryHLT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'HLGT',
        name: 'maternalHistoryHLGT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'SOC',
        name: 'maternalHistorySOC',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Relevant medical and past obstetric history',
        name: 'nameOfMedication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Start date',
        name: 'maternalStartDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'End date*',
        name: 'maternalEndDate',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '6.	Investigational Product(s)',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Enter dosing details in a separate row if different from first dose',
    addOrRemoveText: 'investigational product',
    name: 'investigationalProduct',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Indication',
        name: 'indication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'IP name',
        name: 'ipName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Dose amount',
        name: 'dose',
        type: FormFieldTypes.INPUT_NUMBER,
      },
      {
        label: 'Dose measurement unit',
        name: 'doseUnit',
        type: FormFieldTypes.SELECT,
        options: OPTIONS.dose,
      },
      {
        label: 'Dosage Form',
        name: 'dosageForm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Frequency',
        name: 'frequency',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Route',
        name: 'route',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Start Date',
        name: 'startDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Stop Date(or ongoing)',
        name: 'stopDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date of last dose prior to event onset',
        name: 'lastDoseDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Cycle Number',
        name: 'cycleNumber',
        type: FormFieldTypes.INPUT_NUMBER,
      },
    ],
  },
  {
    label: '7.	Outcome Of Pregnancy',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Female has elected to',
    name: 'femaleElection',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.femaleElection,
  },
  {
    label: 'Delivery or termination date',
    name: 'deliveryOrTerminationDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Gestational age at delivery (weeks):',
    name: 'gestationalAgeAtDelivery',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Number of infants/fetuses',
    name: 'numberOfInfantsOrFetuses',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Describe Complications with Pregnancy to Date',
    name: 'complicationsWithPregnancyToDate',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label: '8.	Infant/Foetus Information',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Sex',
    name: 'infantOrFetusSex',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.infantOrFetusSex,
  },
  {
    label: 'Birth Weight (g):',
    name: 'infantOrFetusWeight',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Length (cm):',
    name: 'infantOrFetusLength',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Apgar score (1 min)',
    name: 'infantOrFetusApgarScoreOneMin',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Apgar score (5 min)',
    name: 'infantOrFetusApgarScoreFiveMin',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Apgar score (10 min)',
    name: 'infantOrFetusApgarScoreTenMin',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Outcome (If anomaly, please provide comments in the narrative section)',
    name: 'infantOrFetusOutcome',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.infantOrFetusOutcome,
  },
  {
    label: 'Method of Delivery',
    name: 'infantOrFetusMethodOfDelivery',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.infantOrFetusMethodOfDelivery,
  },
  {
    label: 'If applicable, cause of death',
    name: 'infantOrFetusCauseOfDeath',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Autopsy performed?',
    name: 'infantOrFetusAutopsyPerformed',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.infantOrFetusAutopsyPerformed,
  },
  {
    label: 'Were there any congenital anomalies?',
    name: 'infantOrFetusCongenitalAnomalies',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.infantOrFetusCongenitalAnomalies,
  },
  {
    label: '9.	Narrative And Current Gestational History',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: '',
    name: 'narrativeAndCurrentGestationHistory',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label: '10.	Relevant Diagnostic And Laboratory Tests  (e.g., ultrasound, amniocentesis',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Add relevant tests',
    addOrRemoveText: 'relevant test',
    name: 'relevantTestsDetails',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Test name',
        name: 'testName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date',
        name: 'relevantTestDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Results with units',
        name: 'resultsWithUnits',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Normal range with units',
        name: 'normalRangeWithUnits',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Clinically Significant (Yes/No)',
        name: 'clinicallySignificant',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '11.	Impact Assessment By The Investigator ',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'In the Investigator’s opinion, does the event constitute a Significant Safety Issue',
    name: 'impactAssessmentSafetyIssue',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.impactAssessmentSafetyIssue,
  },
  {
    label: 'Was an Urgent Safety Measure required to be implemented?',
    name: 'impactAssessmentSafetyMeasure',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.impactAssessmentSafetyMeasure,
  },
  {
    label: '12.	Reporter Information',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    addOrRemoveText: 'reporter information',
    name: 'reportInfo',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Investigator’s / Medically Qualified Delegate’s Name',
        name: 'reporterInfoDelegateName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Site Name and Address',
        name: 'reporterInfoSiteName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Contact number',
        name: 'reporterInfoContactNumber',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Person completing the form Name and Position',
        name: 'personCompletingFormName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Email Address',
        name: 'personCompletingFormEmail',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date',
        name: 'personCompletingFormDate',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '13. UPLOADING DOCUMENTS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Upload documents Pregnancy report form, labs, hospital records etc.',
    name: 'uploadDocuments',
    type: FormFieldTypes.UPLOAD,
  },
  {
    label: '14. Case summary',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Is case serious',
    name: 'caseSummaryIsCaseSerious',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.caseSummaryIsCaseSerious,
  },
  {
    label: 'Case Comment',
    name: 'caseSummaryIsCaseComment',
    type: FormFieldTypes.INPUT,
  },
  {
    label: '15. CASE LINKED REFERENCE NUMBERS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Case Linked Reference Numbers',
    name: 'caseLinkedReferenceNumbers',
    type: FormFieldTypes.INPUT,
  },
];

export { PREGNANCY_FORM_FIELDS };
