import React, { FC, useEffect } from 'react';
import { FullContainer } from '../../styles/global';
import { Input, Space, Button, Typography, Form } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useValidateResetToken } from '@/hooks/useValidateResetToken';
import { useResetPassword } from '@/hooks/useResetPassword';
import { validatePassword } from '@Components/forms/validators/password';
import notification from '@/services/notification';
import { ROUTES } from '../../constants';

const { Title, Text } = Typography;

const ResetPassword: FC = () => {
  const [form] = Form.useForm();
  const { search } = useLocation();

  const history = useHistory();

  const query = new URLSearchParams(search);
  const token = query.get('token');

  const { error: validateError } = useValidateResetToken(token);

  const {
    request,
    data: resetPasswordData,
    error: resetPasswordError,
    loading: resetting,
  } = useResetPassword();

  const onFinish = (values: { password: string }) => {
    if (token) {
      request({
        token,
        password: values.password,
      });
    }
  };

  useEffect(() => {
    if (resetPasswordError) {
      notification.error('Failed to update password.');
    }
  }, [resetPasswordError]);

  useEffect(() => {
    if (resetPasswordData) {
      notification.success('Password updated successfully.');
      history.push(ROUTES.LOGIN);
    }
  }, [resetPasswordData]);

  return (
    <FullContainer alignItems="center" justifyContent="center">
      {validateError ? (
        <Title>Something went wrong</Title>
      ) : (
        <Space direction="vertical">
          <Title level={4}>Reset Password?</Title>
          <Text>Enter the new password.</Text>
          <Form form={form} name="reset-password" onFinish={onFinish} disabled={resetting}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, validator: validatePassword }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Space>
      )}
    </FullContainer>
  );
};

export default ResetPassword;
