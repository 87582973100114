import { Redirect } from 'react-router-dom';

import { useRedirect } from '@/hooks/useRedirect';

interface PermissionRouterProps {
  children: JSX.Element;
}

const PermissionRouter = ({ children }: PermissionRouterProps) => {
  const { redirect } = useRedirect();
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return <>{children}</>;
};

export default PermissionRouter;
