import { API } from '../constants';
import CLIENT from './client';
import { DownloadFormQuery, FormDTO, GetFormQueryDto, UpdateFormBodyDto } from '@/interfaces';
import * as qs from 'qs';

export const createForm = (body: Omit<FormDTO, '_id' | 'creator'>) => {
  return CLIENT.post(API.FORMS, body);
};

export const getForm = (id: string) => {
  return CLIENT.get(API.FORM(id));
};

export const getForms = (params: GetFormQueryDto) => {
  return CLIENT.get(API.FORMS, params as JSON);
};

export const updateForm = (body: UpdateFormBodyDto) => {
  return CLIENT.patch(API.FORM(body._id), body);
};

export const deleteForm = (id: string) => {
  return CLIENT.delete(API.FORM(id));
};

export const downloadForms = (query?: DownloadFormQuery) => {
  const stringifyQuery = query ? qs.stringify(query, { arrayFormat: 'comma' }) : '';
  return CLIENT.download(`${API.FORM_DOWNLOAD}?${stringifyQuery}`).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const filename = response.headers['content-disposition']
      .split('; ')[1]
      .replace(/"|\\/g, '')
      .replace('filename=', '');
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
};
