import React, { useContext, useEffect, useMemo } from 'react';
import { FullContainer } from '@/styles/global';
import { SettingOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Divider, Form, Input, Select } from 'antd';
import { Link } from 'react-router-dom';
import ModuleHeader from '@Components/layout/module-header';
import { HEADER_ICON, L_SPACING } from '@Constants/ui';
import { ModuleBody } from '@Components/layout/module-body';
import { ROUTES } from '@/constants';
import { UserForm } from '@Components/forms/user';
import { getUser, updateUser } from '@/store/slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/configureStore';
import styled from 'styled-components';
import { ChangePasswordForm } from '@Components/forms/change-password';
import { Maybe, UserDTO } from '@/interfaces';
import { ChangePasswordFormBody } from '@Components/forms/change-password';
import { PageLoadingSpin } from '@Components/page-loading-spin';
import { success } from '@/services/notification';
import { DATA_SAVE } from '@Constants/strings';
import { Models, USER_ACTIONS } from '@/services/permission';
import { AccountState } from '@/store/slices/account';
import { PermissionContext } from '@/contexts/permission';

const Section = styled.div`
  flex-direction: column;

  h2 {
    margin-bottom: ${L_SPACING}px;
  }
`;

const AccountSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const account: AccountState = useSelector((state: RootState) => state.account);
  const user = useSelector((state: RootState) => state.user.detail.data);
  const permission = useContext(PermissionContext);

  const editableFields = useMemo(() => {
    const currentUser = new Models.User(account);

    return {
      email: Boolean(permission?.can(USER_ACTIONS.UPDATE, currentUser, 'email')),
      firstName: Boolean(permission?.can(USER_ACTIONS.UPDATE, currentUser, 'firstName')),
      lastName: Boolean(permission?.can(USER_ACTIONS.UPDATE, currentUser, 'lastName')),
      role: Boolean(permission?.can(USER_ACTIONS.UPDATE, currentUser, 'role')),
    };
  }, [account]);

  const renderHeader = () => {
    const title = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ROUTES.ACCOUNT}>Account</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
    return (
      <ModuleHeader actions={[]} title={title} icon={<SettingOutlined style={HEADER_ICON} />} />
    );
  };

  const onUpdateUserProfile = (body: Maybe<UserDTO>) => {
    if (body) {
      dispatch(updateUser({ ...body, _id: account.id! })).then(({ payload }) => {
        payload && payload.data && success(DATA_SAVE);
      });
    }
  };

  const onUpdatePassword = (body: ChangePasswordFormBody) => {
    if (body.password) {
      dispatch(updateUser({ password: body.password, _id: account.id! })).then(({ payload }) => {
        payload && payload.data && success(DATA_SAVE);
      });
    }
  };

  const renderBody = () => {
    if (!user) return <PageLoadingSpin spinning={true} />;
    return (
      <ModuleBody justifyContent={'flex-start'}>
        <Section>
          <h2>User Profile</h2>
          <UserForm
            onSubmit={onUpdateUserProfile}
            editableFields={editableFields}
            formName={'user-form'}
            initialValues={user}
            formLayout={'vertical'}
          />
          <Button type={'primary'} htmlType={'submit'} form={'user-form'}>
            Save
          </Button>
        </Section>
        <Divider />
        <Section>
          <h2>Password</h2>
          <ChangePasswordForm
            onSubmit={onUpdatePassword}
            formLayout={'vertical'}
            formName={'change-password-form'}
          />
          <Button type={'primary'} htmlType={'submit'} form={'change-password-form'}>
            Save
          </Button>
        </Section>
      </ModuleBody>
    );
  };

  useEffect(() => {
    dispatch(getUser(account.id!));
  }, []);

  return (
    <FullContainer direction={'column'} overflow={'hidden'}>
      {renderHeader()}
      {renderBody()}
    </FullContainer>
  );
};

export default AccountSettings;
