export const LOGIN = '/auth/login';
export const LOGOUT = '/auth/logout';
export const SESSION_STATUS = '/auth/session-status';
export const FORM = (id: string) => `/forms/${id}`;
export const FORMS = '/forms';
export const FORM_DOWNLOAD = '/forms/download';
export const STUDIES = '/studies';
export const STUDY = (id: string) => `/studies/${id}`;
export const USER = (id: string) => `/users/${id}`;
export const USERS = '/users';
export const UPLOAD_FILE = '/files/upload';
export const DOWNLOAD_FILE = (id: string) => `/files/${id}/download`;
export const SEND_RESET_EMAIL = '/password-reset/send-reset-email';
export const VALIDATE_RESET_TOKEN = '/password-reset/validate-reset-token';
export const RESET_PASSWORD = '/password-reset/reset-password';
