import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { accountAPI } from '../../api';
import { COMMON } from '../../constants';
import { LoginBody, Maybe } from '@/interfaces';

const { APP_STATUS, ACCOUNT_ROLES } = COMMON;

// INIT STATE

export interface AccountState {
  id: Maybe<string>;
  email: string;
  isLogin: boolean;
  isAdmin: boolean;
  isMonitor: boolean;
  isUser: boolean;
  isStudyAdmin: boolean;
  role: string;
  firstName: string;
  lastName: string;
  status: string;
}

const initialState = {
  id: undefined,
  isLogin: false,
  isAdmin: false,
  isMonitor: false,
  isStudyAdmin: false,
  isUser: false,
  role: 'unknown',
  firstName: '',
  lastName: '',
  email: '',
  status: APP_STATUS.INIT,
} as AccountState;

// ACTIONS
const login = createAsyncThunk('account/login', async (body: LoginBody, thunkAPI) => {
  const response = await accountAPI.login(body);
  return response.data;
});

const logout = createAsyncThunk('account/logout', async (thunkAPI) => {
  const response = await accountAPI.logout();
  return response.data;
});

const getSessionStatus = createAsyncThunk('account/getSessionStatus', async (thunkAPI) => {
  const response = await accountAPI.getSessionStatus();
  return response.data;
});

// REDUCERS
const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.status = APP_STATUS.LOADING;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      return {
        ...data,
        isLogin: true,
        isAdmin: data.role === ACCOUNT_ROLES.ADMIN,
        isStudyAdmin: data.role === ACCOUNT_ROLES.STUDY_ADMIN,
        isMonitor: data.role === ACCOUNT_ROLES.MONITOR,
        isUser: data.role === ACCOUNT_ROLES.USER,
        status: APP_STATUS.DONE,
      };
    });
    builder.addCase(login.rejected, () => initialState);

    builder.addCase(logout.fulfilled, (state, action) => initialState);

    builder.addCase(getSessionStatus.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      if (data.authenticated) {
        return {
          ...data.user,
          isLogin: data.authenticated,
          isAdmin: data.user.role === ACCOUNT_ROLES.ADMIN,
          isStudyAdmin: data.user.role === ACCOUNT_ROLES.STUDY_ADMIN,
          isMonitor: data.user.role === ACCOUNT_ROLES.MONITOR,
          isUser: data.user.role === ACCOUNT_ROLES.USER,
          status: APP_STATUS.DONE,
        };
      }
      return {
        ...initialState,
        status: APP_STATUS.DONE,
      };
    });
    builder.addCase(getSessionStatus.rejected, (state, action) => {
      state.status = APP_STATUS.ERROR;
    });
  },
});

export { login, logout, getSessionStatus };
export default accountSlice.reducer;
