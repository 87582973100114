import { DeleteOutlined, FolderOpenOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import React, { FC } from 'react';
import { DELETE_CONFIRM } from '@Constants/strings';
import { TableWrapper } from '@/styles/global';
import { XS_SPACING } from '@Constants/ui';
import { StudyDTO } from '@/interfaces';

export interface StudiesTableProps {
  studies: StudyDTO[];
  onDeleteStudy?: (study: StudyDTO) => void;
  onOpenStudy?: (study: StudyDTO) => void;
  onEditStudy?: (study: StudyDTO) => void;
}

const StudiesTable: FC<StudiesTableProps> = ({
  studies,
  onDeleteStudy,
  onOpenStudy,
  onEditStudy,
}) => {
  const StudiesTableColumns = [
    {
      title: 'Study',
      dataIndex: 'name',
    },
    {
      title: 'Actions',
      render: (_: any, data: StudyDTO) => {
        return (
          <>
            {onOpenStudy && (
              <Tooltip title="Study Detail">
                <Button
                  style={{ marginRight: XS_SPACING }}
                  icon={<FolderOpenOutlined />}
                  type={'primary'}
                  ghost
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenStudy(data);
                  }}
                />
              </Tooltip>
            )}
            {onEditStudy && (
              <Tooltip title="Edit Study">
                <Button
                  style={{ marginRight: XS_SPACING }}
                  icon={<EditOutlined />}
                  type={'primary'}
                  ghost
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditStudy(data);
                  }}
                />
              </Tooltip>
            )}
            {onDeleteStudy && (
              <Popconfirm
                title={DELETE_CONFIRM(data.name)}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  onDeleteStudy(data);
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete Study">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  return (
    <TableWrapper>
      <Table rowKey={(record) => record._id} columns={StudiesTableColumns} dataSource={studies} />
    </TableWrapper>
  );
};

export default StudiesTable;
