import { useCallback, useState } from 'react';
import { SendResetEmailDto } from '@/interfaces';
import { sendResetEmail } from '@/api/password-reset';

export const useSendResetEmail = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<unknown>();

  const request = useCallback(async (body: SendResetEmailDto) => {
    setError(null);
    try {
      setLoading(true);
      const data = await sendResetEmail(body);
      setData(data);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }, []);
  return {
    loading,
    data,
    error,
    request,
  };
};
