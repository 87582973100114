import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, Tag } from 'antd';
import React, { FC, useContext } from 'react';
import { DELETE_CONFIRM } from '@Constants/strings';
import { TableWrapper } from '@/styles/global';
import { XS_SPACING } from '@Constants/ui';
import { UserDTO } from '@/interfaces';
import { OPTIONS } from '@/constants';
import { find, get } from 'lodash';
import { USER_ACTIONS, Models } from '@/services/permission';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/configureStore';
import { PermissionContext } from '@/contexts/permission';

export interface UserTableProps {
  users: UserDTO[];
  onDeleteUser?: (user: UserDTO) => void;
  onEditUser: (user: UserDTO) => void;
  studies: { label: string; value: string }[];
}

const UsersTable: FC<UserTableProps> = ({ users, studies, onDeleteUser, onEditUser }) => {
  const account = useSelector((state: RootState) => state.account);
  const permission = useContext(PermissionContext);
  const UsersTableColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (value: string) => get(find(OPTIONS.USER_ROLES, { value }), 'label'),
    },
    {
      title: 'Associated Studies',
      dataIndex: 'associatedStudies',
      render: (value: string) =>
        studies.filter((s) => value.includes(s.value)).map((s) => <Tag>{s.label}</Tag>),
    },
    {
      title: 'Actions',
      render: (_: any, data: UserDTO) => {
        return (
          <>
            {permission?.can(USER_ACTIONS.UPDATE, new Models.User(data)) && (
              <Tooltip title="Edit User">
                <Button
                  style={{ marginRight: XS_SPACING }}
                  icon={<EditOutlined />}
                  type={'primary'}
                  ghost
                  onClick={(e) => {
                    e.stopPropagation();
                    onEditUser(data);
                  }}
                />
              </Tooltip>
            )}
            {onDeleteUser && permission?.can(USER_ACTIONS.DELETE, new Models.User(data)) && (
              <Popconfirm
                title={DELETE_CONFIRM(`${data.firstName} ${data.lastName}`)}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  onDeleteUser(data);
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete User">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  return (
    <TableWrapper>
      <Table rowKey={(record) => record._id} columns={UsersTableColumns} dataSource={users} />
    </TableWrapper>
  );
};

export default UsersTable;
