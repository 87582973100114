import { UserDTO } from '@/interfaces';
import { AccountState } from '@/store/slices/account';

export class User {
  static get modelName() {
    return 'User';
  }

  public id: string;
  public email: string;
  public password?: string;
  public role: string;
  public firstName: string;
  public lastName: string;
  constructor(data: UserDTO | AccountState) {
    this.id = (data as UserDTO)._id || (data as AccountState)?.id || '';
    this.email = data.email;
    this.role = data.role;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }
}
