export const OPTIONS = {
  adverseReaction: [
    {
      label: 'Patient died',
      value: 'PATIENT_DIED',
    },
    {
      label: 'Involved or prolonged inpatient hospitalisation',
      value: 'HOSPITALISATION',
    },
    {
      label: 'Involved persistence or significant disability or incapacity',
      value: 'DISABILITY_OR_INCAPACITY',
    },
    {
      label: 'Life threatening',
      value: 'LIFE_THREATENING',
    },
    {
      label: 'Is a congenital anomaly/ birth defect',
      value: 'IS_DEFECT',
    },
    {
      label: 'Is a medically important event',
      shortLabel: 'Is a medically important event',
      value: 'IS_MEDICALLY_EVENT',
    },
  ],
  reactionAbate: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'NA',
      value: 'NA',
    },
  ],
  reactionReappear: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'NA',
      value: 'NA',
    },
  ],
  reportSource: [
    {
      label: 'Study',
      value: 'STUDY',
    },
    {
      label: 'Literature',
      value: 'LITERATURE',
    },
    {
      label: 'Health professional',
      value: 'HEALTH_PROFESSIONAL',
    },
    {
      label: 'HCP',
      value: 'HCP',
    },
  ],
  reportType: [
    {
      label: 'Initial',
      value: 'INITIAL',
    },
    {
      label: 'Followup',
      value: 'FOLLOW_UP',
    },
  ],
};
