import countryList from 'react-select-country-list';

export const USER_ROLES = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Study Admin', value: 'STUDY_ADMIN' },
  { label: 'Monitor', value: 'MONITOR' },
  { label: 'User', value: 'USER' },
];

export const COUNTRIES = countryList().getData();
