export const XXS_SPACING = 2;
export const XS_SPACING = 4;
export const S_SPACING = 8;
export const M_SPACING = 16;
export const L_SPACING = 24;
export const XL_SPACING = 42;

export const M_PADDING = 16;

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const UI_DATE_FORMAT = 'DD/MM/YYYY';
export const UI_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const UI_TIME_FORMAT = 'HH:mm';

export const HEADER_ICON = { fontSize: '22', marginRight: S_SPACING };
