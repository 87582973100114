export const APP_STATUS = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

export const ACCOUNT_ROLES = {
  ADMIN: 'ADMIN',
  STUDY_ADMIN: 'STUDY_ADMIN',
  MONITOR: 'MONITOR',
  USER: 'USER',
};
