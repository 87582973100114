import { Layout } from 'antd';
import styled from 'styled-components';

export const AppContent = styled(Layout.Content)`
  display: flex;
  background: whitesmoke;
  flex-direction: row;
  overflow: auto;
  position: relative;
  flex-grow: 2;
`;

export const Workspace = styled(Layout)`
  align-items: stretch;
  background: whitesmoke;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
`;

interface FlexDivProps {
  alignItems?: string;
  direction?: string;
  justifyContent?: string;
  overflow?: string;
  padding?: number;
}

export const FlexDiv = styled.div<FlexDivProps>`
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'stretch')};
  display: flex;
  flex-grow: 2;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
  overflow: ${(props) => (props.overflow ? props.overflow : 'auto')};
  padding: ${(props) => (props.padding ? props.padding : 0)};
`;

export const FullContainer = styled(FlexDiv)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
