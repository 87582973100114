import {
  Button,
  Col,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Space,
  TimePicker,
  Select,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { DATE_FORMAT, TIME_FORMAT } from './constants/date';
import { FormListContainer, FormListElementContainer } from './styles';
import { Canvas } from '@Components/custom-input/canvas';
import UploadDocuments from '../custom-input/upload';

export enum FormFieldTypes {
  DESCRIPTION, // for display purposes only
  INPUT,
  INPUT_NUMBER,
  TEXT_AREA,
  CHECK_BOX,
  RADIO,
  DATE_PICKER,
  RANGE_PICKER,
  TIME_PICKER,
  FORM_LIST,
  SELECT,
  CANVAS,
  UPLOAD,
}

export enum DescriptionTypes {
  H1,
  H2,
  H3,
  P,
}

type Option =
  | string
  | {
      label: string;
      shortLabel?: string;
      value: string;
    };

type GeneralFormField = {
  readonly label: string;
  readonly name: NamePath;
  readonly type: Exclude<
    FormFieldTypes,
    | FormFieldTypes.CHECK_BOX
    | FormFieldTypes.RADIO
    | FormFieldTypes.FORM_LIST
    | FormFieldTypes.DESCRIPTION
    | FormFieldTypes.SELECT
    | FormFieldTypes.DATE_PICKER
    | FormFieldTypes.RANGE_PICKER
  >;
};

type DatePickerField = Omit<GeneralFormField, 'type'> & {
  readonly type: FormFieldTypes.DATE_PICKER;
  readonly format?: string;
  readonly picker?: 'time' | 'date' | 'month' | 'week' | 'quarter' | 'year' | undefined;
};

type DateRangePicker = Omit<GeneralFormField, 'type'> & {
  readonly type: FormFieldTypes.RANGE_PICKER;
  readonly allowEmpty?: [boolean, boolean];
};

type RadioOrCheckBoxField = Omit<GeneralFormField, 'type'> & {
  readonly type: FormFieldTypes.RADIO | FormFieldTypes.CHECK_BOX;
  readonly options: Option[];
};

type SelectField = Omit<GeneralFormField, 'type'> & {
  readonly type: FormFieldTypes.SELECT;
  readonly options: Option[];
};

type DescriptionField = Omit<GeneralFormField, 'type' | 'name'> & {
  readonly type: FormFieldTypes.DESCRIPTION;
  readonly descriptionType: DescriptionTypes;
};

type FormListField = Omit<GeneralFormField, 'type' | 'label'> & {
  readonly label?: string;
  readonly addOrRemoveText: string;
  readonly type: FormFieldTypes.FORM_LIST;
  readonly children: (
    | GeneralFormField
    | RadioOrCheckBoxField
    | SelectField
    | DatePickerField
    | DateRangePicker
  )[];
};

export type FormField =
  | GeneralFormField
  | DescriptionField
  | FormListField
  | RadioOrCheckBoxField
  | SelectField
  | DatePickerField
  | DateRangePicker;

type DynamicFormFieldsProps = {
  fields: FormField[];
};

const DynamicFormFields = ({ fields }: DynamicFormFieldsProps) => {
  const renderField = (field: FormField, key: string, restField: object = {}) => {
    switch (field.type) {
      case FormFieldTypes.DESCRIPTION:
        switch (field.descriptionType) {
          case DescriptionTypes.H1:
            return <h1 key={key}>{field.label}</h1>;
          case DescriptionTypes.H2:
            return <h2 key={key}>{field.label}</h2>;
          case DescriptionTypes.H3:
            return <h3 key={key}>{field.label}</h3>;
          case DescriptionTypes.P:
            return <p key={key}>{field.label}</p>;
          default:
            throw new Error(`Unexpected descriptionType ${field.descriptionType}`);
        }
      case FormFieldTypes.INPUT:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <Input />
          </Form.Item>
        );
      case FormFieldTypes.INPUT_NUMBER:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <InputNumber />
          </Form.Item>
        );
      case FormFieldTypes.TEXT_AREA:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <Input.TextArea rows={6} />
          </Form.Item>
        );
      case FormFieldTypes.CANVAS:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <Canvas />
          </Form.Item>
        );
      case FormFieldTypes.SELECT:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <Select
              showSearch
              filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
              }
            >
              {field.options.map((option, idx) => {
                const isOptionString = typeof option === 'string';
                if (isOptionString) {
                  return (
                    <Select.Option value={option} key={idx}>
                      {option}
                    </Select.Option>
                  );
                } else {
                  return (
                    <Select.Option value={option.value} key={idx}>
                      {option.label}
                    </Select.Option>
                  );
                }
              })}
            </Select>
          </Form.Item>
        );
      case FormFieldTypes.CHECK_BOX:
        if (!field.options) {
          throw new Error('options is undefined for checkbox field');
        }
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <Checkbox.Group>
              {field.options.map((option, idx) => {
                const isOptionString = typeof option === 'string';
                if (isOptionString) {
                  return (
                    <Col key={idx}>
                      <Checkbox value={option}>{option}</Checkbox>
                    </Col>
                  );
                } else {
                  return (
                    <Col key={idx}>
                      <Checkbox value={option.value}>{option.label}</Checkbox>
                    </Col>
                  );
                }
              })}
            </Checkbox.Group>
          </Form.Item>
        );
      case FormFieldTypes.RADIO:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <Radio.Group>
              <Space direction="vertical">
                {field.options.map((option, idx) => {
                  const isOptionString = typeof option === 'string';
                  if (isOptionString) {
                    return (
                      <Radio value={option} key={idx}>
                        {option}
                      </Radio>
                    );
                  } else {
                    return (
                      <Radio value={option.value} key={idx}>
                        {option.label}
                      </Radio>
                    );
                  }
                })}
              </Space>
            </Radio.Group>
          </Form.Item>
        );

      case FormFieldTypes.DATE_PICKER:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <DatePicker format={field.format || DATE_FORMAT} picker={field.picker} />
          </Form.Item>
        );
      case FormFieldTypes.RANGE_PICKER:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <DatePicker.RangePicker format={DATE_FORMAT} allowEmpty={field.allowEmpty} />
          </Form.Item>
        );
      case FormFieldTypes.FORM_LIST: {
        if (!field.children) {
          throw new Error('children is required for form list');
        }
        return (
          <FormListContainer key={key}>
            {field.label && <h3>{field.label}</h3>}
            <Form.List name={field.name}>
              {(formListFields, { add, remove }) => (
                <>
                  {formListFields.map(({ key, name, ...formListRestField }) => (
                    <FormListElementContainer key={key}>
                      {field.children.map((childField, subIdx) =>
                        renderField(
                          { ...childField, name: [name, childField.name as string] },
                          `${name}-${childField.name}-${subIdx}`,
                          formListRestField
                        )
                      )}
                      <Button
                        type="dashed"
                        onClick={() => remove(name)}
                        icon={<MinusCircleOutlined />}
                      >
                        {`Remove ${field.addOrRemoveText}`}
                      </Button>
                    </FormListElementContainer>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {`Add ${field.addOrRemoveText}`}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </FormListContainer>
        );
      }
      case FormFieldTypes.TIME_PICKER:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <TimePicker format={TIME_FORMAT} />
          </Form.Item>
        );
      case FormFieldTypes.UPLOAD:
        return (
          <Form.Item label={field.label} name={field.name} key={key} {...restField}>
            <UploadDocuments />
          </Form.Item>
        );
    }
  };

  return (
    <>
      {fields.map((field, idx) => {
        return renderField(field, `field-${idx}`);
      })}
    </>
  );
};

export default DynamicFormFields;
