import React, { FC } from 'react';
import { Form, Input, Select } from 'antd';
import { FormLayout } from 'antd/lib/form/Form';
import { validatePassword } from '@Components/forms/validators/password';
import { RuleObject } from 'rc-field-form/lib/interface';

export interface ChangePasswordFormBody {
  password: string;
  confirmPassword: string;
}

interface ChangePasswordFormProps {
  onSubmit: (body: ChangePasswordFormBody) => void | Promise<any>;
  formLayout?: FormLayout;
  formName: string;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({ onSubmit, formLayout, formName }) => {
  const [form] = Form.useForm();

  const checkConfirmPass = (
    rule: RuleObject,
    value: any,
    callback: (error?: string) => void
  ): Promise<void | any> | void => {
    const { password, confirmPassword } = form.getFieldsValue();
    if (password !== confirmPassword) {
      callback('Password is not matched');
    } else {
      callback();
    }
  };

  return (
    <Form
      form={form}
      name={formName}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout={formLayout}
    >
      <Form.Item
        label={'New Password'}
        name={'password'}
        rules={[{ required: true, validator: validatePassword }]}
      >
        <Input type={'password'} />
      </Form.Item>
      <Form.Item
        label={'Confirm Password'}
        name={'confirmPassword'}
        rules={[{ validator: checkConfirmPass }]}
      >
        <Input type={'password'} />
      </Form.Item>
    </Form>
  );
};

export { ChangePasswordForm };
