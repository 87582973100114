import { DELETE_CONFIRM } from '@Constants/strings';
import { Badge, Button, Input, Popconfirm } from 'antd';
import { ButtonType } from 'antd/lib/button';
import React, { FC, ReactNode } from 'react';
import { S_SPACING } from '@Constants/ui';
import { BORDER_COLOR } from '@Constants/colors';
import { FlexDiv } from '@/styles/global';
import styled from 'styled-components';

export const Header = styled(FlexDiv)`
  background: white;
  border: 1px solid ${BORDER_COLOR};
  flex-grow: 0;
  flex-shrink: 0;
  font-size: large;
  height: 60px;
  margin-top: 4px;
  padding: 0 24px 0 24px;
`;

interface Action {
  label: string;
  icon?: string | ReactNode;
  type?: ButtonType | 'danger';
  isBadge?: boolean;
  onClick?: () => void;
}

interface ModuleHeaderProps {
  actions: Action[];
  controls?: ReactNode[];
  icon?: ReactNode;
  onSearch?: (keyword: string) => void;
  searchPlaceholder?: string;
  showSearch?: boolean;
  title: string | ReactNode;
}

const ModuleHeader: FC<ModuleHeaderProps> = (props) => {
  /**
   * Render a badge button on the header
   * @param action
   * @return {JSX.Element}
   */
  const renderBadgeButton = (action: Action) => {
    return (
      <Badge dot key={action.label}>
        {renderButton(action)}
      </Badge>
    );
  };

  /**
   * Render a button on the header
   * @param action
   * @return {JSX.Element}
   */
  const renderButton = (action: Action) => {
    if (action.type === 'danger') {
      return (
        <Popconfirm
          key={action.label}
          title={DELETE_CONFIRM()}
          onConfirm={(e) => {
            e?.stopPropagation();
            action.onClick?.();
          }}
          onCancel={(e) => {
            e?.stopPropagation();
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button
            icon={action.icon}
            ghost
            danger
            style={{ marginLeft: S_SPACING }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {action.label}
          </Button>
        </Popconfirm>
      );
    } else {
      return (
        <Button
          key={action.label}
          icon={action.icon}
          ghost
          type={action.type || 'primary'}
          style={{ marginLeft: S_SPACING }}
          onClick={action.onClick}
        >
          {action.label}
        </Button>
      );
    }
  };

  return (
    <Header>
      <FlexDiv justifyContent={'flex-start'} alignItems={'center'}>
        {props.icon}
        {props.title}
      </FlexDiv>
      <FlexDiv justifyContent={'flex-end'} alignItems={'center'}>
        {props.showSearch && (
          <Input.Search
            placeholder={props.searchPlaceholder}
            onSearch={props.onSearch}
            style={{ width: 360 }}
          />
        )}
        {props.controls}
        {props.actions &&
          props.actions.map((action) => {
            if (action.isBadge) {
              return renderBadgeButton(action);
            } else {
              return renderButton(action);
            }
          })}
      </FlexDiv>
    </Header>
  );
};

export default ModuleHeader;
