import React, { FC } from 'react';
import { Form, Input, Select } from 'antd';
import { Maybe, USER_ROLES, UserDTO } from '@/interfaces';
import { withModal } from '@Components/hoc/withModal';
import { OPTIONS } from '@/constants';
import { FormLayout } from 'antd/lib/form/Form';

interface UserFormProps {
  onSubmit: (body?: UserDTO) => void | Promise<any>;
  editableFields: Record<string, boolean>;
  formName: string;
  initialValues: Maybe<UserDTO>;
  formLayout?: FormLayout;
  studies?: { label: string; value: string }[];
  studyAdmin?: boolean;
}

const UserForm: FC<UserFormProps> = ({
  onSubmit,
  editableFields,
  formName,
  initialValues,
  formLayout,
  studies,
  studyAdmin,
}) => {
  const [form] = Form.useForm();
  const ROLES = studyAdmin
    ? OPTIONS.USER_ROLES.filter((r) => r.value !== USER_ROLES.ADMIN)
    : OPTIONS.USER_ROLES;

  return (
    <Form
      form={form}
      name={formName}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
      layout={formLayout}
    >
      <Form.Item
        label={'Email'}
        name={'email'}
        rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}
      >
        <Input disabled={!editableFields.email} />
      </Form.Item>
      <Form.Item
        label={'First Name'}
        name={'firstName'}
        rules={[{ required: true, message: 'Missing field' }]}
      >
        <Input disabled={!editableFields.firstName} />
      </Form.Item>
      <Form.Item
        label={'Last Name'}
        name={'lastName'}
        rules={[{ required: true, message: 'Missing field' }]}
      >
        <Input disabled={!editableFields.lastName} />
      </Form.Item>
      <Form.Item
        label={'Role'}
        name={'role'}
        rules={[{ required: true, message: 'Missing field' }]}
      >
        <Select options={ROLES} disabled={!editableFields.role} />
      </Form.Item>
      {studies && studies.length > 0 && (
        <Form.Item label={'Associated Studies'} name={'associatedStudies'}>
          <Select
            options={studies}
            disabled={!editableFields.associatedStudies}
            mode="multiple"
            placement={'topLeft'}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export { UserForm };
export default withModal(UserForm);
