import { Form, Input } from 'antd';

type FixedFormFieldsProps = {
  subjectId: string;
  centerId: string;
  ip?: string;
};

const FixedFormFields = ({ subjectId, centerId, ip }: FixedFormFieldsProps) => {
  return (
    <>
      <h2>Form Information</h2>
      <Form.Item label="Subject">
        <Input value={subjectId} disabled />
      </Form.Item>
      <Form.Item label="Center">
        <Input value={centerId} disabled />
      </Form.Item>
      {ip && (
        <Form.Item label="IP">
          <Input value={ip} disabled />
        </Form.Item>
      )}
    </>
  );
};

export default FixedFormFields;
