import styled from 'styled-components';
import { BORDER_COLOR } from '@Constants/colors';
import { FlexDiv } from '@/styles/global';

export const ModuleBody = styled(FlexDiv)`
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  padding: 12px 24px;
  background-color: white;
  border-left: 1px solid ${BORDER_COLOR};
  border-right: 1px solid ${BORDER_COLOR};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
`;
