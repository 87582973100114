import {
  DeleteOutlined,
  FolderOpenOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import React, { FC } from 'react';
import { DELETE_CONFIRM } from '@Constants/strings';
import { TableWrapper } from '@/styles/global';
import { XS_SPACING } from '@Constants/ui';
import { FormDTO, StudyDTO, UserDTO } from '@/interfaces';
import { uniqBy } from 'lodash';
import { FilterValue, TablePaginationConfig } from 'antd/lib/table/interface';
import { LockButton, UnlockButton } from './styles';

export interface FormTableProps {
  forms: FormDTO[];
  users: UserDTO[];
  studies: StudyDTO[];
  onDeleteForm?: (form: FormDTO) => void;
  onOpenForm?: (form: FormDTO) => void;
  onLockForm?: (form: FormDTO) => void;
  onUnlockForm?: (form: FormDTO) => void;
  onChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => void;
}

const FormTable: FC<FormTableProps> = ({
  forms,
  users,
  onDeleteForm,
  onOpenForm,
  onLockForm,
  onUnlockForm,
  studies,
  onChange,
}) => {
  const subjectFilter = uniqBy(forms, 'subject').map((i) => ({
    text: i.subject,
    value: i.subject,
  }));

  const centerFilter = uniqBy(forms, 'center').map((i) => ({
    text: i.center,
    value: i.center,
  }));

  const FormTableColumns = [
    {
      title: 'Study',
      dataIndex: 'study',
      render: (value: string) => studies.find((s) => s._id === value)?.name,
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      filters: subjectFilter,
      onFilter: (value: string | number | boolean, record: FormDTO) => record.subject === value,
      filterSearch: true,
      sorter: (a: FormDTO, b: FormDTO) => (a.subject > b.subject ? 1 : -1),
    },
    {
      title: 'Center',
      dataIndex: 'center',
      filters: centerFilter,
      onFilter: (value: string | number | boolean, record: FormDTO) => record.center === value,
      filterSearch: true,
      sorter: (a: FormDTO, b: FormDTO) => (a.center > b.center ? 1 : -1),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      render: (value: string) => {
        const user = users.find((u) => u._id === value);
        return user ? `${user?.firstName} ${user?.lastName}` : '';
      },
    },
    {
      title: 'Locked',
      dataIndex: 'locked',
      render: (value: boolean) => {
        return value ? 'Yes' : ' No ';
      },
    },
    {
      title: 'Actions',
      render: (_: any, data: FormDTO) => {
        return (
          <>
            {onOpenForm && (
              <Tooltip title="Form Detail">
                <Button
                  style={{ marginRight: XS_SPACING }}
                  icon={<FolderOpenOutlined />}
                  type={'primary'}
                  ghost
                  onClick={(e) => {
                    e.stopPropagation();
                    onOpenForm(data);
                  }}
                />
              </Tooltip>
            )}
            {data.locked
              ? onUnlockForm && (
                  <Tooltip title="Unlock form">
                    <UnlockButton
                      style={{ marginRight: XS_SPACING, color: '#ff7a45' }}
                      icon={<UnlockOutlined />}
                      type={'primary'}
                      ghost
                      onClick={(e) => {
                        e.stopPropagation();
                        onUnlockForm(data);
                      }}
                    />
                  </Tooltip>
                )
              : onLockForm && (
                  <Tooltip title="Lock form">
                    <LockButton
                      style={{ marginRight: XS_SPACING }}
                      icon={<LockOutlined />}
                      type={'primary'}
                      ghost
                      onClick={(e) => {
                        e.stopPropagation();
                        onLockForm(data);
                      }}
                    />
                  </Tooltip>
                )}
            {onDeleteForm && (
              <Popconfirm
                title={DELETE_CONFIRM(data._id)}
                onConfirm={(e) => {
                  e?.stopPropagation();
                  onDeleteForm(data);
                }}
                onCancel={(e) => {
                  e?.stopPropagation();
                }}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete Form">
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    ghost
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  return (
    <TableWrapper>
      <Table
        rowKey={(record) => record._id}
        columns={FormTableColumns}
        dataSource={forms}
        onChange={onChange}
      />
    </TableWrapper>
  );
};

export default FormTable;
