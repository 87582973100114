import { COUNTRIES } from '@Constants/options';

export const OPTIONS = {
  eventType: [
    {
      label: 'SAE',
      value: 'SAE',
    },
    {
      label: 'AESI',
      value: 'AESI',
    },
  ],
  reportType: [
    {
      label: 'Initial',
      value: 'INITIAL',
    },
    {
      label: 'Follow-up',
      value: 'FOLLOW_UP',
    },
  ],
  phase: [
    {
      label: 'Phase I',
      value: 'I',
    },
    {
      label: 'Phase I/IIa',
      value: 'I/IIa',
    },
    {
      label: 'Phase II',
      value: 'II',
    },
    {
      label: 'Phase III',
      value: 'III',
    },
    {
      label: 'Phase IV',
      value: 'IV',
    },
  ],
  blindingStatus: [
    {
      label: 'Blinded',
      value: 'BLINDED',
    },
    {
      label: 'Open-label',
      value: 'OPEN_LABEL',
    },
  ],
  followUps: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  country: COUNTRIES,
  sex: [
    {
      label: 'Female',
      value: 'FEMALE',
    },
    {
      label: 'Male',
      value: 'MALE',
    },
    {
      label: 'Other',
      value: 'OTHER',
    },
  ],
  seriousnessCriteria: [
    {
      label: 'Results in death',
      value: 'RESULTS_IN_DEATH',
    },
    {
      label: 'Is life-threatening',
      value: 'IS_LIFE_THREATENING',
    },
    {
      label: 'Requires in-patient hospitalization or prolongation of existing hospitalization',
      value: 'REQUIRES_HOSPITALIZATION',
    },
    {
      label: 'Results in a persistent or significant disability/incapacity',
      value: 'RESULTS_IN_INCAPACITY',
    },
    {
      label: 'Is a congenital anomaly/ birth defect',
      value: 'IS_DEFECT',
    },
    {
      label: 'Is a medically important event',
      shortLabel: 'Is a medically important event',
      value: 'IS_MEDICALLY_EVENT',
    },
    {
      label: 'N/A – Adverse Event of Special Interest',
      value: 'NA',
    },
  ],
  gradeSeverity: [
    {
      label: 'Grade 1 (mild)',
      value: 'MILD',
    },
    {
      label: 'Grade 2 (moderate)',
      value: 'MODERATE',
    },
    {
      label: 'Grade 3 (severe)',
      value: 'SEVERE',
    },
    {
      label: 'Grade 4 (life-threatening)',
      value: 'LIFE_THREATENING',
    },
    {
      label: 'Grade 5 (death)',
      value: 'DEATH',
    },
  ],
  outcomeForSAEAESI: [
    {
      label: 'Recovered/resolved',
      value: 'RECOVERED',
    },
    {
      label: 'Recovering/resolving',
      value: 'RECOVERING',
    },
    {
      label: 'Not recovered/not resolved',
      value: 'NOT_RECOVERED',
    },
    {
      label: 'Recovered/resolved with sequelae',
      value: 'RECOVERED_WITH_SEQUELAE',
    },
    {
      label: 'Fatal',
      value: 'FATAL',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
  ],

  outcomeForSAEAESIAutopsyPerformed: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
  ],
  wasTreatmentGivenForTheSAEOrAESI: [
    {
      label: 'Yes (please provide details by adding the treatment(s))',
      shortLabel: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],

  testFindings: [
    {
      label: 'Yes (Please provide details by adding the test(s))',
      shortLabel: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],

  relevantConcomitantMedications: [
    {
      label: 'Yes (Please provide details by adding the medication(s))',
      shortLabel: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  concomitantProceduresPerformed: [
    {
      label: 'Yes (Please provide details by adding the procedure(s))',
      shortLabel: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  actionTaken: [
    {
      label: 'Dose not changed',
      value: 'DOSE_NOT_CHANGED',
    },
    {
      label: 'Dose interrupted',
      value: 'DOSE_INTERRUPTED',
    },
    {
      label: 'Drug withdrawn',
      value: 'DRUG_WITHDRAWN',
    },
    {
      label: 'Dose reduced',
      value: 'DOSE_REDUCED',
    },
    {
      label: 'Not applicable',
      value: 'NA',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
  ],
  eventAbateAfterWithdrawnDoseOfIP: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'Not applicable',
      value: 'NA',
    },
  ],
  eventReoccurUponIntroductionOfIP: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'Not applicable',
      value: 'NA',
    },
  ],

  wasTheIPAllocationUnblindedByInvestigator: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'N/A',
      value: 'N/A',
    },
  ],

  wasSponsorNotifiedPriorToUnblinding: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'N/A',
      value: 'N/A',
    },
  ],

  causalityToIPOrPlacebo: [
    {
      label: 'Definitely Related',
      value: 'DEFINITELY',
    },
    {
      label: 'Probably Related',
      value: 'PROBABLY',
    },
    {
      label: 'Possible Related',
      value: 'POSSIBLE',
    },
    {
      label: 'Unlikely Related',
      value: 'UNLIKELY',
    },
    {
      label: 'Not Related',
      value: 'NOT_RELATED',
    },
  ],

  expectednessAssessment: [
    {
      label: 'Expected',
      value: 'EXPECTED',
    },
    {
      label: 'Unexpected',
      value: 'UNEXPECTED',
    },
  ],

  doesTheEventConstituteASignificantSafetyIssue: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],

  wasAnUrgentSafetyMeasureRequiredToBeImplemented: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],

  caseSummaryIsCaseSerious: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],

  caseSummaryIsCaseListedness: [
    {
      label: 'Expected/Listed ',
      value: 'EXPECTED_LISTED',
    },
    {
      label: 'Unexpected/Unlisted',
      value: 'UNEXPECTED_UNLISTED',
    },
  ],

  caseSummaryIsCaseOutcome: [
    {
      label: 'Recovered/resolved',
      value: 'RECOVERED',
    },
    {
      label: 'Recovering/resolving',
      value: 'RECOVERING',
    },
    {
      label: 'Not recovered/not resolved',
      value: 'NOT_RECOVERED',
    },
    {
      label: 'Recovered/resolved with sequelae',
      value: 'RECOVERED_WITH_SEQUELAE',
    },
    {
      label: 'Fatal',
      value: 'FATAL',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
  ],
  race: [
    {
      label: 'American Indian or Alaska Native',
      value: 'NATIVE_INDIAN_OR_ALASKAN',
    },
    {
      label: 'Asian',
      value: 'ASIAN',
    },
    {
      label: 'Black or African American',
      value: 'BLACK_OR_AFRICAN_AMERICAN',
    },
    {
      label: 'Hawaiian or Pacific Islanders',
      value: 'HAWAIIAN_OR_PACIFIC_ISLANDERS',
    },
    {
      label: 'White',
      value: 'WHITE',
    },
    {
      label: 'Other',
      value: 'OTHER',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
    {
      label: 'Not reported',
      value: 'NOT_REPORTED',
    },
  ],
  ethnicity: [
    {
      label: 'Hispanic or Latino',
      value: 'HISPANIC_OR_LATINO',
    },
    {
      label: 'Not Hispanic or Latino',
      value: 'NOT_HISPANIC_OR_LATINO',
    },
    {
      label: 'Not Reported',
      value: 'NOT_REPORTED',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
  ],
  dose: [
    {
      label: 'MBq/kg',
      value: 'MBq/kg',
    },
    {
      label: 'ML',
      value: 'ML',
    },
    {
      label: 'Microgram',
      value: 'Microgram',
    },
    {
      label: 'Gram',
      value: 'Gram',
    },
  ],
  iPOrPlacebo: [
    {
      label: 'IP',
      value: 'IP',
    },
    {
      label: 'Placebo',
      value: 'PLACEBO',
    },
  ],
};
