import React, { FC } from 'react';
import { Checkbox, Form, Input } from 'antd';
import { StudyDTO } from '@/interfaces';
import { withModal } from '@Components/hoc/withModal';
import { FORM_TYPES } from '@/interfaces';
import styled from 'styled-components';

interface ResearchFormProps {
  onSubmit: (body?: StudyDTO) => void | Promise<any>;
  isEdit: boolean;
  formName: string;
  study: string;
  initData?: ResearchFormPayloadProps;
}

export interface ResearchFormPayloadProps {
  study?: string;
  center?: string;
  subject?: string;
  locked?: boolean;
  types?: string[];
}

const StyledFormContainer = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
  }
`;

const ResearchForm: FC<ResearchFormProps> = ({ initData, onSubmit, isEdit, formName }) => {
  const [form] = Form.useForm();

  return (
    <StyledFormContainer>
      <Form
        form={form}
        name={formName}
        onFinish={onSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={initData}
      >
        <Form.Item
          label={'Study'}
          name={'study'}
          rules={[{ required: true, message: 'Missing field' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={'Center'}
          name={'center'}
          rules={[{ required: true, message: 'Missing field' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'Subject'}
          name={'subject'}
          rules={[{ required: true, message: 'Missing field' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={'Lock form'} name={'locked'} valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item
          label={'Forms'}
          name={'types'}
          rules={[{ required: true, message: 'Missing field' }]}
        >
          <Checkbox.Group>
            <Checkbox value={FORM_TYPES.CIOMS}>CIOMS</Checkbox>
            <Checkbox value={FORM_TYPES.PREGNANCY}>Pregnancy Report</Checkbox>
            <Checkbox value={FORM_TYPES.SAE_AESI}>SAE AESI Report</Checkbox>
            <Checkbox value={FORM_TYPES.ADVERSE_EVENTS}>Adverse Events Report</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </StyledFormContainer>
  );
};

export { ResearchForm };
export default withModal(ResearchForm);
