import { FormFieldTypes, FormField, DescriptionTypes } from '../dynamic-form-fields';
import { OPTIONS } from './sae-aesi-options';
import { COUNTRIES } from '@Constants/options';

const SAE_AESI_FORM_FIELDS: FormField[] = [
  {
    label: '1. GENERAL INFORMATION',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Protocol Number',
    name: 'protocolNumber',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Protocol Title',
    name: 'protocolTitle',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Please indicate if the event represents a SAE or AESI by ticking one of the boxes',
    name: 'eventType',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.eventType,
  },
  {
    label: 'Study Phase',
    name: 'studyPhase',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.phase,
  },
  {
    label: 'Blinding Status',
    name: 'blindingStatus',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.blindingStatus,
  },
  {
    label: 'Date event reported',
    name: 'dateEventReported',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date of site awareness of Initial report',
    name: 'dateOfInitialReport',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date of site awareness of Follow-up report(NA for Initial reports)',
    name: 'dateOfFollowUpReport',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Country',
    name: 'country',
    type: FormFieldTypes.SELECT,
    options: COUNTRIES,
  },
  {
    label: 'Sponsor Name and Address ',
    name: 'sponsorNameAndAddress',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Report type',
    name: 'reportType',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.reportType,
  },
  {
    label: 'Record follow-up report detail',
    addOrRemoveText: 'Follow Up Detail',
    name: 'followUps',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Follow-up Number',
        name: 'followUpNumber',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Follow-up Date',
        name: 'followUpDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Is Follow up significant?',
        name: 'followUpSignificant',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.followUps,
      },
    ],
  },
  {
    label: 'Safety Received Date',
    name: 'pharmacovigilanceTeamReceivedDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Received Time',
    name: 'pharmacovigilanceTeamReceivedTime',
    type: FormFieldTypes.TIME_PICKER,
  },
  {
    label: 'Tracked Case Number',
    name: 'pharmacovigilanceTeamTrackedCaseNumber',
    type: FormFieldTypes.INPUT,
  },
  {
    label: '2. SUBJECT INFORMATION',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Sex',
    name: 'sex',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.sex,
  },
  {
    label: 'Age at the time of event onset(Years)',
    name: 'ageOfEventOnset',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Age group',
    name: 'ageGroup',
    type: FormFieldTypes.INPUT,
  },

  {
    label: 'Weight(kg)',
    name: 'weight',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Height(cm)',
    name: 'height',
    type: FormFieldTypes.INPUT_NUMBER,
  },
  {
    label: 'Race',
    name: 'race',
    type: FormFieldTypes.SELECT,
    options: OPTIONS.race,
  },
  {
    label: 'Other race (not listed)',
    name: 'otherRace',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Ethnicity',
    name: 'ethnicity',
    type: FormFieldTypes.SELECT,
    options: OPTIONS.ethnicity,
  },
  {
    label: '3. INVESTIGATIONAL PRODUCT(S)',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    addOrRemoveText: 'a investigational product',
    name: 'investigationalProducts',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Indication',
        name: 'indication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'IP name',
        name: 'ipName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'IP start time',
        name: 'ipStartTime',
        type: FormFieldTypes.TIME_PICKER,
      },
      {
        label: 'Dose amount',
        name: 'dose',
        type: FormFieldTypes.INPUT_NUMBER,
      },
      {
        label: 'Dose measurement unit',
        name: 'doseUnit',
        type: FormFieldTypes.SELECT,
        options: OPTIONS.dose,
      },
      {
        label: 'Dosage Form',
        name: 'dosageForm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Frequency',
        name: 'frequency',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Route',
        name: 'route',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Start Date',
        name: 'startDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Stop Date(or ongoing)',
        name: 'stopDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date of last dose prior to event onset',
        name: 'lastDoseDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Cycle Number',
        name: 'cycleNumber',
        type: FormFieldTypes.INPUT_NUMBER,
      },
    ],
  },
  {
    label: '4. EVENT DETAILS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Event Term(s) (include specific diagnosis wherever possible)',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    addOrRemoveText: 'a event',
    name: 'event',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Event Term',
        name: 'eventTerm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'LLT',
        name: 'eventTermLLT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'PT',
        name: 'eventTermPT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'HLT',
        name: 'eventTermHLT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'HLGT',
        name: 'eventTermHLGT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'SOC',
        name: 'eventTermSOC',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date of onset of first sign/symptom of event',
        name: 'dateOfFirstSignOfEvent',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Start time (24 hr format)',
        name: 'startTime',
        type: FormFieldTypes.TIME_PICKER,
      },
      {
        label: 'Seriousness Criteria',
        name: 'seriousnessCriteria',
        type: FormFieldTypes.CHECK_BOX,
        options: OPTIONS.seriousnessCriteria,
      },
      {
        label: 'Seriousness Criteria Hospital Admission Date',
        name: 'seriousnessCriteriaHospitalAdmissionDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Seriousness Criteria Hospital Discharge Date',
        name: 'seriousnessCriteriaHospitalDischargeDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Seriousness Criteria more details about important medical event',
        name: 'seriousnessCriteriaMedicallyImportantEvent',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Grade/Severity',
        name: 'gradeSeverity',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.gradeSeverity,
      },
      {
        label: 'Narrative',
        name: 'caseDescription',
        type: FormFieldTypes.TEXT_AREA,
      },
    ],
  },
  {
    label: '5. OUTCOME FOR SAE/AESI',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: '',
    name: 'outcomeForSAEAESI',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.outcomeForSAEAESI,
  },
  {
    label: 'Recovered/resolved Date',
    name: 'outcomeForSAEAESIRecoveredDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Recovered/resolved End time',
    name: 'outcomeForSAEAESIRecoveredEndTime',
    type: FormFieldTypes.TIME_PICKER,
  },
  {
    label: 'Recovered/resolved with sequelae Date',
    name: 'outcomeForSAEAESIRecoveredWithSequelaeDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Recovered/resolved with sequelae End time',
    name: 'outcomeForSAEAESIRecoveredWithSequelaeEndTime',
    type: FormFieldTypes.TIME_PICKER,
  },
  {
    label: 'Fatal Date',
    name: 'outcomeForSAEAESIFatalDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Cause of death (LLT)',
    name: 'outcomeForSAEAESICauseOfDeathLLT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Cause of death (PT)',
    name: 'outcomeForSAEAESICauseOfDeathPT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Cause of death (HLT)',
    name: 'outcomeForSAEAESICauseOfDeathHLT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Cause of death (HLGT)',
    name: 'outcomeForSAEAESICauseOfDeathHLGT',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Cause of death (SOC)',
    name: 'outcomeForSAEAESICauseOfDeathSOC',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Was an autopsy performed?',
    name: 'outcomeForSAEAESIAutopsyPerformed',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.outcomeForSAEAESIAutopsyPerformed,
  },
  {
    label: '6. TREATMENT OF SAE/AESI',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Was treatment given for the SAE/AESI?',
    name: 'wasTreatmentGivenForTheSAEOrAESI',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.wasTreatmentGivenForTheSAEOrAESI,
  },
  {
    label: 'If the medication is still ongoing, please skip end date',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    addOrRemoveText: 'treatment',
    name: 'treatmentOfSAE/AESI',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Name of medication',
        name: 'nameOfMedication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Indication',
        name: 'indication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Dose',
        name: 'dose',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Dose unit (g, mg, ml, ug or other: specify)',
        name: 'doseUnit',
        type: FormFieldTypes.INPUT,
      },
      {
        label:
          'Route (oral, IV, rectal, inhalation, sublingual, topical, vaginal, S/C or other: specify',
        name: 'route',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Frequency (once, bd, tds, qid, prn, continuous or other: specify',
        name: 'frequency',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Start Date',
        name: 'startDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'End Date',
        name: 'endDate',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '7. RELEVANT LABORATORY/DIAGNOSTIC TESTS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Are there any test/laboratory findings relevant to the event?',
    name: 'testFindings',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.testFindings,
  },
  {
    addOrRemoveText: 'a test',
    name: 'laboratory/diagnosticTests',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Coded Term',
        name: 'codedTerm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Test name',
        name: 'testName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Indication',
        name: 'indication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date test performed',
        name: 'dateTestPerformed',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Test value',
        name: 'testValue',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Test units',
        name: 'testUnits',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Reference range with units',
        name: 'referenceRange',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Clinically Significant (Yes or No)',
        name: 'clinicallySignificant',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '8. CONCOMITANT MEDICATIONS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: '',
    name: 'relevantConcomitantMedications',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.relevantConcomitantMedications,
  },
  {
    label: '',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    addOrRemoveText: 'a medication',
    name: 'concomitantMedications',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Name of medication',
        name: 'nameOfMedication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Indication',
        name: 'indication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Dose with units (g, mg, ml, ug or other: specify)',
        name: 'doseWithUnits',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Dosage form',
        name: 'dosageForm',
        type: FormFieldTypes.INPUT,
      },
      {
        label:
          'Route (oral, IV, rectal, inhalation, sublingual, topical, vaginal, S/C or other: specify',
        name: 'route',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Frequency (once, bd, tds, qid, prn, continuous or other: specify',
        name: 'frequency',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Start date ',
        name: 'startDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'End date ',
        name: 'endDate',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '9. RELEVANT CONCOMITANT PROCEDURES',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Are there any concomitant procedures performed?',
    name: 'concomitantProceduresPerformed',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.concomitantProceduresPerformed,
  },
  {
    addOrRemoveText: 'a procedure',
    name: 'relevantConcomitantProcedures',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Coded term',
        name: 'codedTerm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Procedure name',
        name: 'procedureName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Indication',
        name: 'indication',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date of procedure',
        name: 'dateOfProcedure',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Other comments/findings',
        name: 'otherComments',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: "10. SUBJECT'S RELEVANT MEDICAL/SURGICAL/ALLERGY HISTORY",
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    addOrRemoveText: 'a history',
    name: 'subjectHistory',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Reported term',
        name: 'nameOfMedicalConditionReportedTerm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Name of medical condition (LLT)',
        name: 'nameOfMedicalConditionLLT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Name of medical condition (PT)',
        name: 'nameOfMedicalConditionPT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Name of medical condition (HLT)',
        name: 'nameOfMedicalConditionHLT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Name of medical condition (HLGT)',
        name: 'nameOfMedicalConditionHLGT',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Name of medical condition (SOC)',
        name: 'nameOfMedicalConditionSOC',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Onset Date',
        name: 'onsetDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Is this condition current/past',
        name: 'conditionCurrentOrPast',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'End Date',
        name: 'endDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Is the subject receiving treatment for condition?',
        name: 'subjectReceivingTreatment',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '11. ACTION TAKEN WITH THE IP/PLACEBO IN RESPONSE TO THE EVENT',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Action taken with',
    name: 'actionTakenWithIPOrPlacebo',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.iPOrPlacebo,
  },
  {
    label: 'Action taken',
    name: 'actionTaken',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.actionTaken,
  },
  {
    label:
      '* If drug increased or reduced, provide new dose amount, new dose start date and frequency below',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    label: 'New dose amount',
    name: 'actionTakenNewDoseAmount',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'New dose measurement unit',
    name: 'actionTakenNewDoseUnit',
    type: FormFieldTypes.SELECT,
    options: OPTIONS.dose,
  },
  {
    label: 'New dose start date',
    name: 'actionTakenNewDoseStartDate',
    type: FormFieldTypes.DATE_PICKER,
  },
  {
    label: 'New dose frequency',
    name: 'actionTakenNewDoseFrequency',
    type: FormFieldTypes.INPUT,
  },
  {
    label: '* If drug interrupted, provide interruption date and date re-started below',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    label: 'Date dose interrupted',
    name: 'actionTakenDateDoseInterrupted',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date dose re-started',
    name: 'actionTakenDateDoseRestarted',
    type: FormFieldTypes.INPUT,
  },
  {
    label: '* If drug withdrawn, provide the date the drug was withdrawn',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    label: 'Date drug was withdrawn',
    name: 'actionTakenDateDrugWasWithdrawn',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Did event abate after interrupting/withdrawn dose of IP?',
    name: 'eventAbateAfterWithdrawnDoseOfIP',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.eventAbateAfterWithdrawnDoseOfIP,
  },
  {
    label: 'Did event reoccur upon introduction of IP?',
    name: 'eventReoccurUponIntroductionOfIP',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.eventReoccurUponIntroductionOfIP,
  },
  {
    label: 'Was the IP allocation unblinded by Investigator?',
    name: 'wasTheIPAllocationUnblindedByInvestigator',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.wasTheIPAllocationUnblindedByInvestigator,
  },
  {
    label: 'Was Sponsor notified prior to unblinding?',
    name: 'wasSponsorNotifiedPriorToUnblinding',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.wasSponsorNotifiedPriorToUnblinding,
  },
  {
    label: 'Date of unblinding',
    name: 'dateOfUnblinding',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Name of person who performed unblinding',
    name: 'nameOfPersonWhoPerformedUnblinding',
    type: FormFieldTypes.INPUT,
  },
  {
    label: '12. AS REPORTED CAUSALITY AND EXPECTEDNESS ASSESSMENT',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Assessment type',
    name: 'assessmentTypeIPOrPlacebo',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.iPOrPlacebo,
  },
  {
    label: 'Causality to IP/Placebo',
    name: 'causalityToIPOrPlacebo',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.causalityToIPOrPlacebo,
  },
  {
    label:
      'If Not related, what is the most likely cause? Please provide explanation as applicable',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    label: 'Concomitant medication',
    name: 'concomitantMedication',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Disease (name)',
    name: 'diseaseName',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Other illness',
    name: 'otherIllness',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Other Explanation: (Please provide details below)',
    name: 'OtherExplanation',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label: '13. IMPACT ASSESSMENT BY THE INVESTIGATOR',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: "In the Investigator's opinion, does the event constitute a Significant Safety Issue",
    name: 'doesTheEventConstituteASignificantSafetyIssue',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.doesTheEventConstituteASignificantSafetyIssue,
  },
  {
    label: 'Was an Urgent Safety Measure required to be implemented?',
    name: 'wasAnUrgentSafetyMeasureRequiredToBeImplemented',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.wasAnUrgentSafetyMeasureRequiredToBeImplemented,
  },
  {
    label: '14.SPONSOR’s ASSESSMENT',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Causality',
    name: 'sponsorAssessmentCausality',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.causalityToIPOrPlacebo,
  },
  {
    label: 'Expectedness Assessment',
    name: 'sponsorExpectednessAssessment',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.expectednessAssessment,
  },
  {
    label: '15. REPORTER INFORMATION',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    addOrRemoveText: 'reporter information',
    name: 'reportInfo',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Name of the person completing the form',
        name: 'nameOfThePersonCompletingTheForm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Position',
        name: 'position',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Site Name',
        name: 'siteName',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Site Address',
        name: 'siteAddress',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Email',
        name: 'email',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Contact No',
        name: 'contactNo',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Date',
        name: 'reportInformationDate',
        type: FormFieldTypes.INPUT,
      },
    ],
  },
  {
    label: '16. INVESTIGATOR’S DETAILS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Name',
    name: 'investigatorName',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Email',
    name: 'investigatorEmail',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Date',
    name: 'investigatorDate',
    type: FormFieldTypes.INPUT,
  },
  {
    label: '17. OVERALL CASE DETAILS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Case summary',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.P,
  },
  {
    label: 'Is case serious',
    name: 'caseSummaryIsCaseSerious',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.caseSummaryIsCaseSerious,
  },
  {
    label: 'Case Causality',
    name: 'caseSummaryIsCaseCausality',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.causalityToIPOrPlacebo,
  },
  {
    label: 'Case Listedness',
    name: 'caseSummaryIsCaseListedness',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.caseSummaryIsCaseListedness,
  },
  {
    label: 'Case Outcome',
    name: 'caseSummaryIsCaseOutcome',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.caseSummaryIsCaseOutcome,
  },
  {
    label: 'Case Comment',
    name: 'caseSummaryIsCaseComment',
    type: FormFieldTypes.TEXT_AREA,
  },
  {
    label: '18. UPLOADING DOCUMENTS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: '',
    name: 'uploadDocuments',
    type: FormFieldTypes.UPLOAD,
  },
  {
    label: '19. CASE LINKED REFERENCE NUMBERS',
    type: FormFieldTypes.DESCRIPTION,
    descriptionType: DescriptionTypes.H2,
  },
  {
    label: 'Case Linked Reference Numbers',
    name: 'caseLinkedReferenceNumbers',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Sponsor Name and Address',
    name: 'nameAndAddressOfSponsor',
    type: FormFieldTypes.INPUT,
  },
  {
    label: 'Name and Address of PI',
    name: 'nameAndAddressOfPI',
    type: FormFieldTypes.INPUT,
  },
];

export { SAE_AESI_FORM_FIELDS };
