import { API } from '../constants';
import CLIENT from './client';
import { LoginBody } from '../interfaces';

export const login = (body: LoginBody) => {
  return CLIENT.post(API.LOGIN, body);
};

export const logout = () => {
  return CLIENT.post(API.LOGOUT);
};

export const getSessionStatus = () => {
  return CLIENT.get(API.SESSION_STATUS);
};
