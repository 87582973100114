import { API } from '../constants';
import CLIENT from './client';
import { StudyDTO } from '@/interfaces';

export const createStudy = (body: StudyDTO) => {
  return CLIENT.post(API.STUDIES, body);
};

export const getStudies = () => {
  return CLIENT.get(API.STUDIES);
};

export const updateStudy = (id: string, body: StudyDTO) => {
  return CLIENT.patch(API.STUDY(id), body);
};

export const deleteStudy = (id: string) => {
  return CLIENT.delete(API.STUDY(id));
};
