import { API } from '../constants';
import CLIENT from './client';
import { UserDTO } from '@/interfaces';

export const getUsers = () => {
  return CLIENT.get(API.USERS);
};

export const getUser = (id: string) => {
  return CLIENT.get(API.USER(id));
};

export const createUser = (body: UserDTO) => {
  return CLIENT.post(API.USERS, body);
};

export const deleteUser = (id: string) => {
  return CLIENT.delete(API.USER(id));
};

export const updateUser = (body: Partial<UserDTO> & { _id: string }) => {
  return CLIENT.patch(API.USER(body._id), body);
};
