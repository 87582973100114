import { Spin } from 'antd';
import { LoadingSpinWrapper, LoadingSpinMask } from './styles';

type PageLoadingSpinProps = {
  tip?: string;
  spinning: boolean;
};

export const PageLoadingSpin = (
  { tip, spinning }: PageLoadingSpinProps = { tip: 'Loading...', spinning: true }
) => {
  if (!spinning) {
    return null;
  }

  return (
    <LoadingSpinWrapper>
      <Spin size="large" tip={tip} spinning={true} />
      <LoadingSpinMask />
    </LoadingSpinWrapper>
  );
};
