import { RuleObject } from 'rc-field-form/lib/interface';

export const validatePassword = (
  rule: RuleObject,
  value: any,
  callback: (error?: string) => void
): Promise<void | any> | void => {
  if (!value || value?.length < 8) {
    callback('Password length must be at least 8 characters');
  } else {
    callback();
  }
};
