import { Form } from 'antd';
import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  flex: 0;
`;

export const AntdForm = styled(Form)`
  flex: 1;
  overflow: auto;
  padding: 1rem;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const FormListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 0.5rem;
`;
export const FormListElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
`;
