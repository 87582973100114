import { FormFieldTypes, FormField, DescriptionTypes } from '../dynamic-form-fields';
import { OPTIONS } from './adverse-events-options';

const ADVERSE_EVENTS_FORM_FIELDS: FormField[] = [
  {
    label: 'Did the Subject have any adverse events?',
    name: 'haveAdverseEvents',
    type: FormFieldTypes.RADIO,
    options: OPTIONS.haveAdverseEvents,
  },
  {
    label: 'Adverse Events',
    addOrRemoveText: 'adverse event',
    name: 'adverseEvents',
    type: FormFieldTypes.FORM_LIST,
    children: [
      {
        label: 'Adverse Event Verbatim Term',
        name: 'verbatimTerm',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Severity',
        name: 'severity',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.severity,
      },
      {
        label: 'Event Onset Date',
        name: 'onsetDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Event Onset Time (from)',
        name: 'onsetTimeFrom',
        type: FormFieldTypes.TIME_PICKER,
      },
      {
        label: 'Event Onset Time (to)',
        name: 'onsetTimeTo',
        type: FormFieldTypes.TIME_PICKER,
      },
      {
        label: 'Event Ongoing',
        name: 'ongoing',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.ongoing,
      },
      {
        label: 'Event End Date',
        name: 'endDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Relationship to treatment',
        name: 'relationshipToStudyTreatment',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.relationshipToStudyTreatment,
      },
      {
        label: 'Does the AE meet the definition of serious?',
        name: 'meetSerious',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.meetSerious,
      },
      {
        label: 'Date reported to sponsor',
        name: 'reportDate',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Reason AE was considered serious (tick all that apply)',
        name: 'reasonsOfAE',
        type: FormFieldTypes.CHECK_BOX,
        options: OPTIONS.reasonsOfAE,
      },
      {
        label: 'Other action taken',
        name: 'otherActionTaken',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.otherActionTaken,
      },
      {
        label: 'Outcome',
        name: 'outcome',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.outcome,
      },
      {
        label: 'Date of death',
        name: 'dateOfDeath',
        type: FormFieldTypes.INPUT,
      },
      {
        label: 'Was the subject withdrawn from treatment due to this AE?',
        name: 'subjectWithdrawn',
        type: FormFieldTypes.RADIO,
        options: OPTIONS.subjectWithdrawn,
      },
    ],
  },
];

export { ADVERSE_EVENTS_FORM_FIELDS };
