import { ROUTES } from '@/constants';
import { PermissionContext } from '@/contexts/permission';
import { Abilities, USER_ACTIONS, USER_ACTION_SUBJECTS } from '@/services/permission';
import { useContext } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

type Rule = {
  route: {
    path: string;
  };
  permission: Abilities;
};
const REDIRECT_RULES: Rule[] = [
  {
    route: { path: ROUTES.USERS },
    permission: [USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USERS],
  },
];

export const useRedirect = (): {
  redirect?: string;
} => {
  const permission = useContext(PermissionContext);
  const { pathname } = useLocation();

  const matchedRule = REDIRECT_RULES.find((rule) => matchPath(pathname, rule.route));
  if (matchedRule) {
    if (permission?.cannot(...matchedRule.permission)) {
      return { redirect: ROUTES.STUDY_LIST };
    }
  }
  return {};
};
