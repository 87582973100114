import React, { FC, useContext, useEffect, useState } from 'react';
import { FullContainer } from '@/styles/global';
import ModuleHeader from '@Components/layout/module-header';
import { TableOutlined, PlusOutlined } from '@ant-design/icons';
import { omitBy, isEmpty } from 'lodash';
import { Breadcrumb, Dropdown, Menu, Button, Tabs, TabsProps } from 'antd';
import { HEADER_ICON } from '@Constants/ui';
import { ModuleBody } from '@Components/layout/module-body';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/configureStore';
import {
  createForm,
  deleteForm,
  getForms,
  updateForm,
  updateActiveFormType,
} from '@/store/slices/form';
import { DownloadFormQuery, FORM_TYPES, FormDTO, GetFormQueryDto } from '@/interfaces';
import FormTable, { FormTableProps } from '@/containers/study-detail/table';
import { getUsers } from '@/store/slices/user';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { getStudies } from '@/store/slices/study';
import ResearchFormWithModal, { ResearchFormPayloadProps } from '@Components/forms/researchForm';
import { omit } from 'lodash';
import { success } from '@/services/notification';
import { DATA_SAVE } from '@Constants/strings';
import { ROUTES } from '@/constants';
import { FilterValue, TablePaginationConfig } from 'antd/lib/table/interface';
import { formAPI } from '../../api';
import { USER_ACTIONS, USER_ACTION_SUBJECTS } from '@/services/permission';
import { PermissionContext } from '@/contexts/permission';

interface RouteProps {
  studyId: string;
}

const StudyDetail: FC = () => {
  const { studyId } = useParams<RouteProps>();
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const forms = useSelector((state: RootState) => state.form.list.data);
  const users = useSelector((state: RootState) => state.user.list.data);
  const studies = useSelector((state: RootState) => state.study.list.data);
  const activeFormType = useSelector((state: RootState) => state.form.list.activeFormType);
  const study = studies.find((s) => s._id === studyId);
  const permission = useContext(PermissionContext);

  const [showFormModal, setShowFormModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [tableFilter, setTableFilter] = useState<Record<string, FilterValue | null>>();

  const onCreateForm = (body: ResearchFormPayloadProps) => {
    if (body.types && study) {
      const newForms = body.types.map((type) => {
        return {
          ...omit(body, ['types', 'study']),
          data: {},
          type,
          study: study._id,
        } as Omit<FormDTO, '_id' | 'creator'>;
      });
      Promise.all(newForms.map((form) => dispatch(createForm(form)))).then((res) => {
        const { payload } = res[0];
        payload && payload.data && success(DATA_SAVE);
      });
    }
    setShowFormModal(false);
  };

  const onDeleteForm = (form: FormDTO) => {
    dispatch(deleteForm(form._id)).then(({ payload }) => {
      payload && payload.data && success(DATA_SAVE);
    });
  };

  const onOpenFormModal = () => {
    setShowFormModal(true);
    setFormData({ study: study?.name, locked: false });
  };

  const onCloseFormModal = () => {
    setShowFormModal(false);
  };

  const onActiveFormTypeChange = (type: string) => {
    dispatch(updateActiveFormType(type as FORM_TYPES));
  };

  const onOpenForm = (data: FormDTO) => {
    history.push(generatePath(ROUTES.STUDY_FORM, { formId: data._id, studyId }));
  };

  const onLockForm = (data: FormDTO) => {
    dispatch(updateForm({ _id: data._id, locked: true }));
  };

  const onUnlockForm = (data: FormDTO) => {
    dispatch(updateForm({ _id: data._id, locked: false }));
  };

  const onTableFilterChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => {
    setTableFilter(filters);
  };

  const onDownloadAll = () => {
    return formAPI.downloadForms(study ? ({ study: [study._id] } as DownloadFormQuery) : {});
  };

  const onDownloadFiltered = () => {
    return formAPI.downloadForms({
      ...omitBy(tableFilter, isEmpty),
      ...(study ? { study: [study._id] } : {}),
    } as DownloadFormQuery);
  };

  const renderHeader = () => {
    const actions = [];

    if (permission?.can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.FORM)) {
      actions.push({
        label: 'Create Form',
        isBadge: false,
        onClick: onOpenFormModal,
        icon: <PlusOutlined />,
      });
    }
    const title = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/studies'}>Studies</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/studies/${studyId}`}>{study?.name}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
    return (
      <ModuleHeader
        controls={[]}
        actions={actions}
        title={title}
        icon={<TableOutlined style={HEADER_ICON} />}
      />
    );
  };

  const renderBody = () => {
    let tabsProps: TabsProps = {
      activeKey: activeFormType,
      type: 'card',
      size: 'large',
      onChange: onActiveFormTypeChange,
    };
    if (permission?.can(USER_ACTIONS.EXPORT, USER_ACTION_SUBJECTS.FORM)) {
      const menu = (
        <Menu>
          <Menu.Item onClick={onDownloadAll} key="download-all">
            <span>Download All</span>
          </Menu.Item>
          <Menu.Item onClick={onDownloadFiltered} key="download-filtered">
            <span>Download filtered result</span>
          </Menu.Item>
        </Menu>
      );
      const barExtraContent = (
        <Dropdown overlay={menu}>
          <Button type={'primary'}>Download</Button>
        </Dropdown>
      );
      tabsProps.tabBarExtraContent = barExtraContent;
    }

    let formTableProps: FormTableProps = {
      forms,
      users,
      studies,
      onChange: onTableFilterChange,
    };

    if (permission?.can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.FORM)) {
      formTableProps.onDeleteForm = onDeleteForm;
    }

    if (
      permission?.can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.FORM) &&
      permission?.can(USER_ACTIONS.UPDATE, USER_ACTION_SUBJECTS.FORM)
    ) {
      formTableProps.onOpenForm = onOpenForm;
      formTableProps.onLockForm = onLockForm;
      formTableProps.onUnlockForm = onUnlockForm;
    }

    return (
      <ModuleBody>
        <Tabs {...tabsProps}>
          <Tabs.TabPane tab={FORM_TYPES.CIOMS} key={FORM_TYPES.CIOMS} />
          <Tabs.TabPane tab={FORM_TYPES.PREGNANCY} key={FORM_TYPES.PREGNANCY} />
          <Tabs.TabPane tab={FORM_TYPES.SAE_AESI} key={FORM_TYPES.SAE_AESI} />
          <Tabs.TabPane tab={FORM_TYPES.ADVERSE_EVENTS} key={FORM_TYPES.ADVERSE_EVENTS} />
        </Tabs>
        <FormTable {...formTableProps} />
      </ModuleBody>
    );
  };

  const renderModals = () => {
    return (
      <ResearchFormWithModal
        onSubmit={onCreateForm}
        onClose={onCloseFormModal}
        formName={'ResearchForm'}
        title={'Create Form'}
        okText={'Submit'}
        show={showFormModal}
        initData={formData}
      />
    );
  };

  useEffect(() => {
    let params: GetFormQueryDto = {};
    params.study = studyId;
    params.type = activeFormType;
    dispatch(getForms(params));
  }, [activeFormType]);

  useEffect(() => {
    dispatch(getStudies());
    dispatch(getUsers());
  }, []);

  return (
    <FullContainer direction={'column'} overflow={'hidden'}>
      {renderHeader()}
      {renderBody()}
      {renderModals()}
    </FullContainer>
  );
};

export default StudyDetail;
