import { Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { FORM_TYPES, FormDTO } from '@/interfaces';
import { CIOMS_FORM_FIELDS } from './constants/cioms';
import DynamicFormFields from './dynamic-form-fields';
import FixedFormFields from './fixed-form-fields';
import { AntdForm, Buttons, FormContainer } from './styles';
import { convertAPIDataToFormData, convertFormDataToAPIData } from './formDataHelper';
import { SAE_AESI_FORM_FIELDS } from './constants/sae-aesi';
import { PREGNANCY_FORM_FIELDS } from './constants/pregnancy';
import { success, warning } from '@/services/notification';
import { ADVERSE_EVENTS_FORM_FIELDS } from './constants/adverse-events';

type FormType = {
  data: FormDTO;
  onUpdate: (data: FormDTO) => void;
  isUpdating: boolean;
};

const Form = ({ data, onUpdate, isUpdating }: FormType) => {
  const [form] = AntdForm.useForm();
  const [hasShownWarning, setShowWarning] = useState(false);

  const dynamicFormFields = useMemo(() => {
    const { type } = data;

    switch (type) {
      case FORM_TYPES.CIOMS:
        return CIOMS_FORM_FIELDS;
      case FORM_TYPES.PREGNANCY:
        return PREGNANCY_FORM_FIELDS;
      case FORM_TYPES.SAE_AESI:
        return SAE_AESI_FORM_FIELDS;
      case FORM_TYPES.ADVERSE_EVENTS:
        return ADVERSE_EVENTS_FORM_FIELDS;
      default:
        throw new Error(`Unexpected form type ${type}`);
    }
  }, [data]);

  const initialValues = useMemo(
    () => convertAPIDataToFormData(dynamicFormFields, data.data),
    [data]
  );

  const handleFormSubmit = async () => {
    await form.validateFields();
    const newData = convertFormDataToAPIData(dynamicFormFields, form.getFieldsValue());
    onUpdate({
      ...data,
      data: newData,
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [data.data]);

  const onValuesChange = (_: any, allValues: any) => {
    if ([FORM_TYPES.PREGNANCY, FORM_TYPES.SAE_AESI].includes(data.type)) {
      showWarningForSAEOrPregnancyExistingForm(allValues);
    }
  };

  const showWarningForSAEOrPregnancyExistingForm = (allValues: any) => {
    if (hasShownWarning) return;
    // @ts-ignore
    if (data?.data?.['reportType'] === 'INITIAL') {
      warning(
        'You have changed field entries, please remember to QC and complete medical review fields again'
      );
      setShowWarning(true);
    }
  };

  return (
    <FormContainer>
      <AntdForm
        onValuesChange={onValuesChange}
        layout={'vertical'}
        form={form}
        disabled={data.locked || isUpdating}
        initialValues={initialValues}
      >
        <FixedFormFields subjectId={data.subject} centerId={data.center} ip={data.data?.ip} />
        <DynamicFormFields fields={dynamicFormFields} />
      </AntdForm>
      {!data.locked && (
        <Buttons>
          <Button type="primary" onClick={handleFormSubmit} loading={isUpdating}>
            {isUpdating ? 'Updating...' : 'Submit'}
          </Button>
        </Buttons>
      )}
    </FormContainer>
  );
};

export default Form;
