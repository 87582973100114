import { API } from '../constants';
import CLIENT from './client';
import { SendResetEmailDto, ValidateResetTokenDto, ResetPasswordDto } from '@/interfaces';

export const sendResetEmail = (body: SendResetEmailDto) => {
  return CLIENT.post(API.SEND_RESET_EMAIL, body);
};

export const validateResetToken = (body: ValidateResetTokenDto) => {
  return CLIENT.post(API.VALIDATE_RESET_TOKEN, body);
};

export const resetPassword = (body: ResetPasswordDto) => {
  return CLIENT.post(API.RESET_PASSWORD, body);
};
