import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../constants';
import Login from '../containers/login';
import { AppContent, Workspace } from '../styles/global';
import AppHeader from '../components/header';
import PasswordRecovery from '../containers/password-recovery';
import ResetPassword from '../containers/reset-password';
// import Footer from '../components/footer';

const PublicRoutes = () => {
  return (
    <Workspace>
      <AppHeader />
      <AppContent>
        <Switch>
          <Route path={ROUTES.LOGIN}>
            <Login />
          </Route>
          <Route path={ROUTES.RECOVERY}>
            <PasswordRecovery />
          </Route>
          <Route path={ROUTES.RESET_PASSWORD}>
            <ResetPassword />
          </Route>
        </Switch>
      </AppContent>
    </Workspace>
  );
};

export default PublicRoutes;
