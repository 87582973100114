import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { formAPI } from '../../api';
import {
  BaseStoreState,
  FormDTO,
  GetFormQueryDto,
  Maybe,
  FORM_TYPES,
  UpdateFormBodyDto,
} from '@/interfaces';

interface FormState {
  list: BaseStoreState<FormDTO[]> & { activeFormType: FORM_TYPES };
  detail: BaseStoreState<Maybe<FormDTO>> & { updating: boolean };
}

const initialState = {
  list: {
    data: [],
    activeFormType: FORM_TYPES.CIOMS,
    loading: false,
    error: null,
  },
  detail: {
    data: undefined,
    loading: false,
    updating: false,
    error: null,
  },
} as FormState;

const createForm = createAsyncThunk(
  'form/createForm',
  async (body: Omit<FormDTO, '_id' | 'creator'>) => {
    const response = await formAPI.createForm(body);
    return response.data;
  }
);

const getForms = createAsyncThunk('form/getForms', async (params: GetFormQueryDto) => {
  const response = await formAPI.getForms(params);
  return response.data;
});

const getForm = createAsyncThunk('form/getForm', async (id: string) => {
  const response = await formAPI.getForm(id);
  return response.data;
});

const updateForm = createAsyncThunk('form/updateForm', async (body: UpdateFormBodyDto) => {
  const response = await formAPI.updateForm(body);
  return response.data;
});

const deleteForm = createAsyncThunk('form/deleteForm', async (id: string) => {
  const response = await formAPI.deleteForm(id);
  return response.data;
});

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateActiveFormType(state, action) {
      state.list.activeFormType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createForm.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.loading = false;
      if (data.type === state.list.activeFormType) {
        state.list.data.push(data);
      }
      state.list.error = null;
    });

    builder.addCase(getForms.pending, (state) => {
      state.list.loading = true;
    });
    builder.addCase(getForms.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.loading = false;
      state.list.data = data;
      state.list.error = null;
    });
    builder.addCase(getForms.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
    });

    builder.addCase(getForm.pending, (state) => {
      state.detail.loading = true;
      state.detail.data = undefined;
    });
    builder.addCase(getForm.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.detail.loading = false;
      state.detail.data = data;
      state.detail.error = null;
    });
    builder.addCase(getForm.rejected, (state, action) => {
      state.detail.loading = false;
      state.detail.error = action.error;
    });

    builder.addCase(updateForm.pending, (state) => {
      state.detail.updating = true;
    });
    builder.addCase(updateForm.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.detail.updating = false;
      state.detail.data = data;
      state.detail.error = null;
      const idx = state.list.data.findIndex((form) => form._id === data._id);
      if (idx !== -1) {
        state.list.data[idx] = data;
      }
    });
    builder.addCase(updateForm.rejected, (state, action) => {
      state.detail.updating = false;
      state.detail.error = action.error;
    });

    builder.addCase(deleteForm.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.data = state.list.data.filter((item) => item._id !== data._id);
    });
  },
});

export const { updateActiveFormType } = formSlice.actions;
export { getForms, deleteForm, createForm, getForm, updateForm };
export default formSlice.reducer;
