import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { studyAPI } from '../../api';
import { BaseStoreState, StudyDTO } from '@/interfaces';

interface StudyState {
  list: BaseStoreState<StudyDTO[]>;
}

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
  },
} as StudyState;

const getStudies = createAsyncThunk('study/getStudies', async () => {
  const response = await studyAPI.getStudies();
  return response.data;
});

const createStudy = createAsyncThunk('study/createStudy', async (body: StudyDTO) => {
  const response = await studyAPI.createStudy(body);
  return response.data;
});

const deleteStudy = createAsyncThunk('study/deleteStudy', async (id: string) => {
  const response = await studyAPI.deleteStudy(id);
  return response.data;
});

const updateStudy = createAsyncThunk('study/updateStudy', async (body: StudyDTO) => {
  const response = await studyAPI.updateStudy(body._id, body);
  return response.data;
});

const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudies.pending, (state) => {
      state.list.loading = true;
    });
    builder.addCase(getStudies.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.loading = false;
      state.list.data = data;
      state.list.error = null;
    });
    builder.addCase(getStudies.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
    });

    builder.addCase(createStudy.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.data.push(data);
    });

    builder.addCase(deleteStudy.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.data = state.list.data.filter((item) => item._id !== data._id);
    });

    builder.addCase(updateStudy.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      const index = state.list.data.findIndex((item) => item._id === data._id);
      state.list.data[index] = data;
    });
  },
});

export { getStudies, createStudy, deleteStudy, updateStudy };
export default studySlice.reducer;
