import React, { FC, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import { Button } from 'antd';
import { BORDER_COLOR } from '@Constants/colors';

interface CanvasProps {
  onChange?: (value: string) => void;
  value?: string;
  containerWidth?: number;
  containerHeight?: number;
  initialImageURL?: string;
  initialImageRatio?: number;
  initialImageOffset?: {
    x: number;
    y: number;
  };
  initialImageSize?: {
    width: number;
    height: number;
  };
}

const CanvasContainer = styled.div`
  border: 1px solid ${BORDER_COLOR};
  display: inline-block;
  position: relative;
`;

const RedrawButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Canvas: FC<CanvasProps> = ({
  containerWidth = 400,
  containerHeight = 200,
  initialImageURL,
  initialImageOffset,
  initialImageRatio = 1,
  initialImageSize = { width: 400, height: 200 },
  onChange,
  value,
}) => {
  const canvasRef = useRef(null);
  const defaultSize = 500;

  useEffect(() => {
    if (initialImageURL && !value) {
      loadImage(initialImageURL);
    }
  }, [initialImageURL]);

  useEffect(() => {
    if (value) {
      loadImage(value);
    }
  }, [value]);

  const loadImage = (value: string) => {
    if (canvasRef.current) {
      (canvasRef.current as any).fromDataURL(value, {
        ratio: initialImageRatio,
        width: initialImageSize?.width,
        height: initialImageSize?.height,
        xOffset: initialImageOffset?.x,
        yOffset: initialImageOffset?.y,
      });
    }
  };

  const onClear = () => {
    if (canvasRef.current) {
      (canvasRef.current as any).clear();
      if (initialImageURL) {
        loadImage(initialImageURL);
        onChange?.(initialImageURL);
      }
    }
  };

  const onStopDrawing = () => {
    if (canvasRef.current) {
      const newValue = (canvasRef.current as any).toDataURL();
      onChange?.(newValue);
    }
  };

  return (
    <CanvasContainer>
      <SignatureCanvas
        onEnd={() => onStopDrawing()}
        canvasProps={{
          width: containerWidth || defaultSize,
          height: containerHeight || defaultSize,
        }}
        ref={canvasRef}
      />
      <RedrawButton onClick={onClear}>Clear</RedrawButton>
    </CanvasContainer>
  );
};
