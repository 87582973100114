export const ACCOUNT = '/account';
export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const RECOVERY = '/recovery';
export const RESET_PASSWORD = '/reset-password';
export const STUDY_LIST = '/studies';
export const STUDY_DETAIL = '/studies/:studyId';
export const STUDY_FORM = '/studies/:studyId/forms/:formId';
export const USERS = '/users';
