import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from '../constants';
import { AppContent, Workspace } from '@/styles/global';
import AppHeader from '../components/header';
import StudyDetail from '../containers/study-detail';
import StudyList from '../containers/study-list';
import FormContainer from '@/containers/form';
import UserList from '@/containers/user-list';
import AccountSettings from '@/containers/account-settings';
import { definePermissionsFor } from '@/services/permission';
import { RootState } from '@/store/configureStore';
import { PermissionContext } from '@/contexts/permission';
import PermissionRouter from '@/components/permission-route';

const AuthRoutes = () => {
  const account = useSelector((state: RootState) => state.account);
  const permission = definePermissionsFor(account);

  return (
    <PermissionContext.Provider value={permission}>
      <PermissionRouter>
        <Workspace>
          <AppHeader />
          <AppContent>
            <Switch>
              <Route path={ROUTES.STUDY_LIST} exact>
                <StudyList />
              </Route>
              <Route path={ROUTES.STUDY_DETAIL} exact>
                <StudyDetail />
              </Route>
              <Route path={ROUTES.STUDY_FORM}>
                <FormContainer />
              </Route>
              <Route path={ROUTES.USERS}>
                <UserList />
              </Route>
              <Route path={ROUTES.ACCOUNT}>
                <AccountSettings />
              </Route>
              <Redirect to={ROUTES.STUDY_LIST} />
            </Switch>
          </AppContent>
        </Workspace>
      </PermissionRouter>
    </PermissionContext.Provider>
  );
};

export default AuthRoutes;
