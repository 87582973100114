import { Form, Checkbox, Input } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { login } from '../../store/slices/account';
import { LoginBtn, RememberMeContainer } from './styles';
import { INPUT_INLINE_ICON_COLOR } from '../../constants/colors';
import { AppDispatch } from '../../store/configureStore';
import { LoginBody } from '../../interfaces';
import { ROUTES } from '../../constants';

const LoginForm = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = (values: LoginBody) => {
    dispatch(login(values));
  };
  return (
    <Form name="login-form" initialValues={{ remember: true }} onFinish={handleSubmit}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: INPUT_INLINE_ICON_COLOR }} />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined style={{ color: INPUT_INLINE_ICON_COLOR }} />}
          className="site-form-item-icon"
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <RememberMeContainer>
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Link className="login-form-forgot" to={ROUTES.RECOVERY}>
            Forgot password
          </Link>
        </RememberMeContainer>
      </Form.Item>
      <Form.Item>
        <LoginBtn type="primary" htmlType="submit">
          Log in
        </LoginBtn>
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
