import { notification } from 'antd';

notification.config({
  placement: 'bottomRight',
  duration: 3,
});

export function success(message: string, title?: string) {
  notification.success({
    message: title || 'Success',
    description: message,
  });
}

export function info(message: string, title?: string) {
  notification.info({
    message: title || 'Information',
    description: message,
  });
}

export function warning(message: string, title?: string) {
  notification.warning({
    message: title || 'Warning',
    description: message,
  });
}

export function error(message: string, title?: string) {
  notification.error({
    message: title || 'Error',
    description: message,
  });
}

export default {
  success,
  info,
  warning,
  error,
};
