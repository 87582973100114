import {
  LoginOutlined,
  LogoutOutlined,
  UserOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useContext } from 'react';
import { Button, Dropdown, Menu, Row, Col, Divider } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import logo from '../../public/images/logo.png';
import { Header } from './styles';
import { ROUTES } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '@/store/configureStore';
import { logout } from '@/store/slices/account';
import { FlexDiv } from '@/styles/global';
import styled from 'styled-components';
import { PRIME_TEXT_COLOR } from '@Constants/colors';
import { XS_SPACING } from '@Constants/ui';
import { USER_ACTIONS, USER_ACTION_SUBJECTS } from '@/services/permission';
import { PermissionContext } from '@/contexts/permission';

const StyledLink = styled(Link)`
  color: ${PRIME_TEXT_COLOR};
  margin-right: 12px;
  margin-left: 12px;
  padding-left: 12px;
  padding-right: 12px;

  span:first-of-type {
    margin-right: ${XS_SPACING}px;
  }

  &:hover {
    background: whitesmoke;
  }
`;

const AppHeader = () => {
  const account = useSelector((state: RootState) => state.account);
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const permission = useContext(PermissionContext);

  const onLoginClick = () => {
    window.location.href = ROUTES.LOGIN;
  };

  const onLogoutClick = () => {
    dispatch(logout());
    window.location.href = ROUTES.LOGIN;
  };

  const onSettingsClick = () => {
    history.push(ROUTES.ACCOUNT);
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={onSettingsClick}>
        <SettingOutlined style={{ marginRight: XS_SPACING }} />
        <span>Account</span>
      </Menu.Item>
      <Menu.Item onClick={onLogoutClick}>
        <LogoutOutlined style={{ marginRight: XS_SPACING }} />
        <span>Log out</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header>
      <Row justify="space-between" align="middle">
        <Col span={4}>
          <Link to={{ pathname: '/' }}>
            <img src={logo} className="logo" alt="unloaded" />
            <span>Avi.eSafety</span>
          </Link>
        </Col>
        {account.isLogin && (
          <Col span={16}>
            <Row align={'middle'}>
              <StyledLink to={{ pathname: '/' }}>
                <AppstoreOutlined />
                <span>Studies</span>
              </StyledLink>
              {permission?.can(USER_ACTIONS.READ, USER_ACTION_SUBJECTS.USERS) && (
                <>
                  <Divider type={'vertical'} />
                  <StyledLink to={{ pathname: '/users' }}>
                    <UserOutlined />
                    <span>Users</span>
                  </StyledLink>
                </>
              )}
            </Row>
          </Col>
        )}
        {!account.isLogin ? (
          <Col span={4} flex={'flex-end'}>
            <FlexDiv justifyContent={'flex-end'}>
              <Button icon={<LoginOutlined />} type="primary" onClick={onLoginClick}>
                Log in
              </Button>
            </FlexDiv>
          </Col>
        ) : (
          <Col span={4}>
            <FlexDiv justifyContent={'flex-end'}>
              <Dropdown overlay={menu}>
                <a>
                  <UserOutlined style={{ marginRight: XS_SPACING }} />
                  <span>
                    {account.firstName} {account.lastName}
                  </span>
                </a>
              </Dropdown>
            </FlexDiv>
          </Col>
        )}
      </Row>
    </Header>
  );
};

export default AppHeader;
