export const OPTIONS = {
  haveAdverseEvents: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  severity: [
    {
      label: '1 mild',
      value: 'MILD',
    },
    {
      label: '2 moderate',
      value: 'MODERATE',
    },
    {
      label: '3 severe',
      value: 'SERVERE',
    },
    {
      label: '4 life threatening',
      value: 'LIFE_THREATENING',
    },
    {
      label: '5 life death',
      value: 'LIFE_DEATH',
    },
  ],
  ongoing: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  relationshipToStudyTreatment: [
    {
      label: 'Not related',
      value: 'NOT_RELATED',
    },
    {
      label: 'Possibly related',
      value: 'POSSIBLY_RELATED',
    },
    {
      label: 'Related (probable)',
      value: 'RELATED',
    },
  ],
  meetSerious: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ],
  subjectWithdrawn: [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
    {
      label: 'Was single procedure/treatment',
      value: 'SINGLE_PROCEDURE',
    },
  ],
  reasonsOfAE: [
    {
      label: 'Event resulted in death',
      value: 'DEATH',
    },
    {
      label: 'Event is life-threatening',
      value: 'LIFE_THREATENING',
    },
    {
      label:
        'Event requires in-patient hospitalization or prolongation of existing hospitalization',
      value: 'REQUIRE_HOSPITALIZATION',
    },
    {
      label: 'Event resulted in persistent or significant disability/incapacity',
      value: 'DISABILITY',
    },
    {
      label: 'Event is a congenital abnormality or birth defect',
      value: 'BIRTH_DEFECT',
    },
    {
      label: 'Event is a medically important event or reaction',
      value: 'MEDICALLY_EVENT',
    },
  ],
  otherActionTaken: [
    {
      label: 'No action',
      value: 'NO_ACTION',
    },
    {
      label: 'Medication commenced',
      value: 'MEDICATION_COMMENCED',
    },
    {
      label: 'Surgical procedure',
      value: 'SURGICAL_PROCEDURE',
    },
  ],
  outcome: [
    {
      label: 'Recovered',
      value: 'RECOVERED',
    },
    {
      label: 'Recovered with sequelae',
      value: 'RECOVERED_WITH_SEQUELAE',
    },
    {
      label: 'Recovering',
      value: 'RECOVERING',
    },
    {
      label: 'Not recovered',
      value: 'NOT_RECOVERED',
    },
    {
      label: 'Unknown',
      value: 'UNKNOWN',
    },
    {
      label: 'Fatel',
      value: 'FATEL',
    },
  ],
};
