import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from './constants/date';
import { FormField, FormFieldTypes } from './dynamic-form-fields';

export const convertFormDataToAPIData = (
  fields: FormField[],
  formData: Record<string, any>
): Record<string, string> => {
  const newData = {
    ...formData,
  };
  fields.forEach((field) => {
    switch (field.type) {
      case FormFieldTypes.TIME_PICKER: {
        if (newData[field.name as string]) {
          newData[field.name as string] = newData[field.name as string].format(TIME_FORMAT);
        }
        break;
      }
      case FormFieldTypes.DATE_PICKER: {
        if (newData[field.name as string]) {
          newData[field.name as string] = newData[field.name as string].format(DATE_FORMAT);
        }
        break;
      }
      case FormFieldTypes.RANGE_PICKER: {
        if (newData[field.name as string]) {
          newData[field.name as string] = [
            newData[field.name as string][0]?.format(DATE_FORMAT),
            newData[field.name as string][1]?.format(DATE_FORMAT),
          ];
        }
        break;
      }
      case FormFieldTypes.FORM_LIST: {
        field.children.forEach((child) => {
          switch (child.type) {
            case FormFieldTypes.TIME_PICKER: {
              if (newData[field.name as string]) {
                newData[field.name as string] = newData[field.name as string].map(
                  (el: Record<string, any>) => {
                    if (!el) {
                      return el;
                    }
                    const newEl = {
                      ...el,
                    };
                    if (el[child.name as string]) {
                      newEl[child.name as string] = el[child.name as string].format(TIME_FORMAT);
                    }
                    return newEl;
                  }
                );
              }
              break;
            }
            case FormFieldTypes.DATE_PICKER: {
              if (newData[field.name as string]) {
                newData[field.name as string] = newData[field.name as string].map(
                  (el: Record<string, any>) => {
                    if (!el) {
                      return el;
                    }
                    const newEl = {
                      ...el,
                    };
                    if (el[child.name as string]) {
                      newEl[child.name as string] = el[child.name as string].format(DATE_FORMAT);
                    }
                    return newEl;
                  }
                );
              }
              break;
            }
            case FormFieldTypes.RANGE_PICKER: {
              if (newData[field.name as string]) {
                newData[field.name as string] = newData[field.name as string].map(
                  (el: Record<string, any>) => {
                    if (!el) {
                      return el;
                    }
                    const newEl = {
                      ...el,
                    };
                    if (el[child.name as string]) {
                      newEl[child.name as string] = [
                        el[child.name as string][0].format(DATE_FORMAT),
                        el[child.name as string][1].format(DATE_FORMAT),
                      ];
                    }
                    return newEl;
                  }
                );
              }
              break;
            }
            default:
              break;
          }
        });
        break;
      }
      default:
        break;
    }
  });
  return newData;
};

export const convertAPIDataToFormData = (
  fields: FormField[],
  apiData: object
): Record<string, any> => {
  const newData: Record<string, any> = {
    ...apiData,
  };
  fields.forEach((field) => {
    switch (field.type) {
      case FormFieldTypes.TIME_PICKER: {
        if (newData[field.name as string]) {
          newData[field.name as string] = moment(newData[field.name as string], TIME_FORMAT);
        }
        break;
      }
      case FormFieldTypes.DATE_PICKER: {
        if (newData[field.name as string]) {
          newData[field.name as string] = moment(newData[field.name as string], DATE_FORMAT);
        }
        break;
      }
      case FormFieldTypes.RANGE_PICKER: {
        if (newData[field.name as string]) {
          newData[field.name as string] = [
            moment(newData[field.name as string][0], DATE_FORMAT),
            newData[field.name as string][1]
              ? moment(newData[field.name as string][1], DATE_FORMAT)
              : null,
          ];
        }
        break;
      }
      case FormFieldTypes.FORM_LIST: {
        field.children.forEach((child) => {
          switch (child.type) {
            case FormFieldTypes.TIME_PICKER: {
              if (newData[field.name as string]) {
                newData[field.name as string] = newData[field.name as string].map(
                  (el: Record<string, any>) => {
                    if (!el) {
                      return el;
                    }
                    const newEl = {
                      ...el,
                    };
                    if (el[child.name as string]) {
                      newEl[child.name as string] = moment(el[child.name as string], TIME_FORMAT);
                    }
                    return newEl;
                  }
                );
              }
              break;
            }
            case FormFieldTypes.DATE_PICKER: {
              if (newData[field.name as string]) {
                newData[field.name as string] = newData[field.name as string].map(
                  (el: Record<string, any>) => {
                    if (!el) {
                      return el;
                    }
                    const newEl = {
                      ...el,
                    };
                    if (el[child.name as string]) {
                      newEl[child.name as string] = moment(el[child.name as string], DATE_FORMAT);
                    }
                    return newEl;
                  }
                );
              }
              break;
            }
            case FormFieldTypes.RANGE_PICKER: {
              if (newData[field.name as string]) {
                newData[field.name as string] = newData[field.name as string].map(
                  (el: Record<string, any>) => {
                    if (!el) {
                      return el;
                    }
                    const newEl = {
                      ...el,
                    };
                    if (el[child.name as string]) {
                      newEl[child.name as string] = [
                        moment(el[child.name as string][0], DATE_FORMAT),
                        moment(el[child.name as string][1], DATE_FORMAT),
                      ];
                    }
                    return newEl;
                  }
                );
              }
              break;
            }
            default:
              break;
          }
        });
        break;
      }
      default:
        break;
    }
  });
  return newData;
};
