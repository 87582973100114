import React, { FC, useEffect, useState, Fragment } from 'react';
import { FullContainer } from '@/styles/global';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Modal } from 'antd';
import { useContext } from 'react';

import ModuleHeader from '@Components/layout/module-header';
import { HEADER_ICON } from '@Constants/ui';
import { ModuleBody } from '@Components/layout/module-body';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/configureStore';
import { createUser, deleteUser, getUsers, updateUser } from '@/store/slices/user';
import UserTable, { UserTableProps } from './table';
import { Maybe, UserDTO } from '@/interfaces';
import { Link, useHistory } from 'react-router-dom';
import UserFormWithModal from '@Components/forms/user';
import { success } from '@/services/notification';
import { DATA_SAVE } from '@Constants/strings';
import { USER_ACTIONS, USER_ACTION_SUBJECTS, Models } from '@/services/permission';
import { PermissionContext } from '@/contexts/permission';
import { getStudies } from '@/store/slices/study';

const UserList: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const users = useSelector((state: RootState) => state.user.list.data);
  const account = useSelector((state: RootState) => state.account);
  const studies = useSelector((state: RootState) => state.study.list.data).map((s) => ({
    label: s.name,
    value: s._id,
  }));
  const permission = useContext(PermissionContext);
  const [passwordPromptModal, contextHolder] = Modal.useModal();

  const [showUserForm, setShowUserForm] = useState(false);
  const [userForm, setUserForm] = useState<Maybe<UserDTO>>();

  const onSubmitUser = (body: UserDTO) => {
    setShowUserForm(false);
    setUserForm(undefined);
    const isEdit = !!userForm;
    if (!isEdit) {
      dispatch(createUser(body)).then(({ payload }) => {
        payload && payload.data && success(DATA_SAVE);
        const config = {
          title: 'Attention',
          content: (
            <Fragment>
              <p>
                Please write down or store the user password. This message will only appear once.
              </p>
              <p>The password is:</p>
              <p>
                <b>{payload.data.password}</b>
              </p>
            </Fragment>
          ),
        };
        passwordPromptModal.info(config);
      });
    } else {
      dispatch(updateUser({ ...userForm, ...body })).then(({ payload }) => {
        payload && payload.data && success(DATA_SAVE);
      });
    }
  };

  const onOpenUserModal = () => {
    setShowUserForm(true);
  };

  const onCloseUserModal = () => {
    setShowUserForm(false);
    setUserForm(undefined);
  };

  const onDeleteUser = (user: UserDTO) => {
    dispatch(deleteUser(user._id)).then(({ payload }) => {
      payload && payload.data && success(DATA_SAVE);
    });
  };

  const onOpenUser = (user: UserDTO) => {
    history.push(`/users/${user._id}`);
  };

  const onEditUser = (user: UserDTO) => {
    setShowUserForm(true);
    setUserForm(user);
  };

  const renderHeader = () => {
    const actions = [];

    if (permission?.can(USER_ACTIONS.CREATE, USER_ACTION_SUBJECTS.USER)) {
      actions.push({
        label: 'Create User',
        isBadge: false,
        onClick: onOpenUserModal,
        icon: <PlusOutlined />,
      });
    }
    const title = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/users'}>Users</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
    return (
      <ModuleHeader actions={actions} title={title} icon={<UserOutlined style={HEADER_ICON} />} />
    );
  };

  const renderBody = () => {
    let userTableProps: UserTableProps = {
      users,
      onEditUser,
      studies,
    };

    if (permission?.can(USER_ACTIONS.DELETE, USER_ACTION_SUBJECTS.USER)) {
      userTableProps.onDeleteUser = onDeleteUser;
    }

    return (
      <ModuleBody>
        <UserTable {...userTableProps} />
      </ModuleBody>
    );
  };

  const renderModals = () => {
    // default editable fields while creating user
    const editableFields = {
      email: true,
      firstName: true,
      lastName: true,
      role: true,
      associatedStudies: true,
    };
    if (userForm) {
      // editable fields while editing exist user
      const currentUser = new Models.User(userForm);
      editableFields.email = Boolean(permission?.can(USER_ACTIONS.UPDATE, currentUser, 'email'));
      editableFields.firstName = Boolean(
        permission?.can(USER_ACTIONS.UPDATE, currentUser, 'firstName')
      );
      editableFields.lastName = Boolean(
        permission?.can(USER_ACTIONS.UPDATE, currentUser, 'lastName')
      );
      editableFields.role = Boolean(permission?.can(USER_ACTIONS.UPDATE, currentUser, 'role'));
      editableFields.associatedStudies = Boolean(
        permission?.can(USER_ACTIONS.UPDATE, currentUser, 'associatedStudies')
      );
    }

    return (
      <Fragment>
        <UserFormWithModal
          onSubmit={onSubmitUser}
          onClose={onCloseUserModal}
          formName={'UserForm'}
          title={userForm ? 'Edit User' : 'Create User'}
          okText={'Submit'}
          show={showUserForm}
          initialValues={userForm}
          editableFields={editableFields}
          studies={studies}
          studyAdmin={account.isStudyAdmin}
        />
        {contextHolder}
      </Fragment>
    );
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getStudies());
  }, []);

  return (
    <FullContainer direction={'column'} overflow={'hidden'}>
      {renderHeader()}
      {renderBody()}
      {renderModals()}
    </FullContainer>
  );
};

export default UserList;
