import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { FullContainer } from '../../styles/global';
import LoginForm from './form';
import { Container, ImageContainer, ImageHeader } from './styles';
import logo from '../../public/images/logo.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/configureStore';

const Login: FC = () => {
  const account = useSelector((state: RootState) => state.account);
  if (account.isLogin) {
    return <Redirect to={'/'} />;
  } else {
    return (
      <FullContainer alignItems="center" justifyContent="center">
        <Container>
          <ImageHeader>
            <ImageContainer src={logo} />
            <h1>Avi.eSafety</h1>
          </ImageHeader>
          <LoginForm />
        </Container>
      </FullContainer>
    );
  }
};

export default Login;
