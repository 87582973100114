import { useState, useEffect } from 'react';

import { validateResetToken } from '@/api/password-reset';

export const useValidateResetToken = (token: string | null) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    const request = async () => {
      if (token) {
        setError(null);
        try {
          setLoading(true);
          const data = await validateResetToken({
            token,
          });
          setData(data);
        } catch (err) {
          setError(err);
        }
        setLoading(false);
      }
    };

    request();
  }, [token]);

  return {
    loading,
    data,
    error,
  };
};
