import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { StudyDTO } from '@/interfaces';
import { withModal } from '@Components/hoc/withModal';

interface StudyFormProps {
  onSubmit: (body?: StudyDTO) => void | Promise<any>;
  isEdit: boolean;
  formName: string;
  initialValues: StudyDTO;
}

const StudyForm: FC<StudyFormProps> = ({ onSubmit, isEdit, formName, initialValues }) => {
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      name={formName}
      onFinish={onSubmit}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
    >
      <Form.Item
        label={'Study Name'}
        name={'name'}
        rules={[{ required: true, message: 'Missing field' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={'Protocol Number'}
        name={'protocolNumber'}
        rules={[{ required: true, message: 'Missing field' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={'IP'} name={'ip'} rules={[{ required: true, message: 'Missing field' }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};

export { StudyForm };
export default withModal(StudyForm);
