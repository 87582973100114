import { useEffect } from 'react';
import { useParams, Link, generatePath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormOutlined } from '@ant-design/icons';
import { Breadcrumb, notification } from 'antd';

import Form from '@Components/form';
import { getStudies } from '@/store/slices/study';
import { FullContainer } from '@/styles/global';
import { ModuleBody } from '@/components/layout/module-body';
import { AppDispatch, RootState } from '@/store/configureStore';
import { getForm, updateForm } from '@/store/slices/form';
import { FormDTO } from '@/interfaces';
import { PageLoadingSpin } from '@Components/page-loading-spin';
import ModuleHeader from '@Components/layout/module-header';
import { HEADER_ICON } from '@Constants/ui';
import { ROUTES } from '@/constants';
import { success } from '@/services/notification';
import { DATA_UPDATE } from '@Constants/strings';

interface RouteProps {
  formId: string;
  studyId: string;
}

const FormContainer = () => {
  const { formId, studyId } = useParams<RouteProps>();
  const dispatch = useDispatch<AppDispatch>();
  const currentFormData = useSelector((state: RootState) => state.form.detail.data);
  const isLoadingCurrentForm = useSelector((state: RootState) => state.form.detail.loading);
  const isUpdatingCurrentForm = useSelector((state: RootState) => state.form.detail.updating);
  const studies = useSelector((state: RootState) => state.study.list.data);
  const study = studies.find((s) => s._id === studyId);

  const handleOnFormUpdate = (formData: FormDTO) => {
    dispatch(updateForm({ _id: formData._id, data: formData.data })).then(
      ({ payload }) => payload?.data && success(DATA_UPDATE)
    );
  };

  useEffect(() => {
    dispatch(getForm(formId));
  }, [formId]);

  useEffect(() => {
    dispatch(getStudies());
  }, []);

  const renderHeader = () => {
    const title = (
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={ROUTES.STUDY_LIST}>Studies</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={generatePath(ROUTES.STUDY_DETAIL, { studyId })}>{study?.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={generatePath(ROUTES.STUDY_FORM, { studyId, formId })}>{`Form${
            currentFormData?.type ? ` - ${currentFormData.type}` : ''
          }`}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    );
    return <ModuleHeader actions={[]} title={title} icon={<FormOutlined style={HEADER_ICON} />} />;
  };

  const renderBody = () => {
    return (
      <ModuleBody>
        {currentFormData && (
          <Form
            data={currentFormData}
            onUpdate={handleOnFormUpdate}
            isUpdating={isUpdatingCurrentForm}
          />
        )}
      </ModuleBody>
    );
  };

  return (
    <FullContainer direction={'column'} overflow={'hidden'}>
      <PageLoadingSpin tip={'Loading...'} spinning={isLoadingCurrentForm} />
      {renderHeader()}
      {renderBody()}
    </FullContainer>
  );
};

export default FormContainer;
