import { Button } from 'antd';
import styled from 'styled-components';
// remember me and forget password container
export const RememberMeContainer = styled.div`
  & > .ant-checkbox-wrapper {
    float: left;
  }
  & > .login-form-forgot {
    float: right;
  }
`;
// login button in login page
export const LoginBtn = styled(Button)`
  width: 100%;
`;

// styled container for login page
export const Container = styled.div`
  height: 360px;
  width: 360px;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 8px;
  background: white;
  box-shadow: 0 0 0 4px #ececec;
  padding: 20px;
`;
//styled logo container for login page
export const ImageContainer = styled.img`
  background-size: contain;
  background: no-repeat center;
  height: 80px;
  width: 80px;
  padding: 10px;
  position: absolute;
  left: 0;
`;

export const ImageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: auto;
  position: relative;
  h1 {
    margin: 0;
  }
`;
