import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userAPI } from '../../api';
import { BaseStoreState, Maybe, UserDTO } from '@/interfaces';

interface UserState {
  list: BaseStoreState<UserDTO[]>;
  detail: BaseStoreState<Maybe<UserDTO>>;
}

const initialState = {
  list: {
    data: [],
    loading: false,
    error: null,
  },
  detail: {
    data: undefined,
    loading: false,
    error: null,
  },
} as UserState;

const getUser = createAsyncThunk('user/getUser', async (id: string) => {
  const response = await userAPI.getUser(id);
  return response.data;
});

const getUsers = createAsyncThunk('user/getUsers', async () => {
  const response = await userAPI.getUsers();
  return response.data;
});

const createUser = createAsyncThunk('user/createUser', async (body: UserDTO) => {
  const response = await userAPI.createUser(body);
  return response.data;
});

const deleteUser = createAsyncThunk('user/deleteUser', async (id: string) => {
  const response = await userAPI.deleteUser(id);
  return response.data;
});

const updateUser = createAsyncThunk(
  'user/updateUser',
  async (body: Partial<UserDTO> & { _id: string }) => {
    const response = await userAPI.updateUser(body);
    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.detail.data = data;
    });

    builder.addCase(getUsers.pending, (state) => {
      state.list.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.loading = false;
      state.list.data = data;
      state.list.error = null;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
    });

    builder.addCase(createUser.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.data.push(data);
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      state.list.data = state.list.data.filter((i) => i._id !== data._id);
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const {
        payload: { data },
      } = action;
      const index = state.list.data.findIndex((item) => item._id === data._id);
      state.list.data[index] = data;
    });
  },
});

export { getUser, getUsers, createUser, deleteUser, updateUser };
export default userSlice.reducer;
