import { getSessionStatus } from '../../store/slices/account';
import React, { useEffect, Fragment, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { COMMON } from '../../constants';
import { AppDispatch } from '../../store/configureStore';
import { RootState } from '../../store/configureStore';

const { INIT, LOADING } = COMMON.APP_STATUS;

interface AuthRouteWrapperProps {
  component: React.ElementType;
  path: string;
}

const AuthRouteWrapper: FC<AuthRouteWrapperProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { component: Component } = props;
  const account = useSelector((state: RootState) => state.account);

  useEffect(() => {
    dispatch(getSessionStatus());
  }, []);

  if (account.status === INIT || account.status === LOADING) {
    return <Fragment />;
  } else {
    return account.isLogin ? <Component {...props} /> : <Redirect to={'/login'} />;
  }
};

export default AuthRouteWrapper;
