import { Layout } from 'antd';
import styled from 'styled-components';
import { BORDER_COLOR } from '../../constants/colors';

export const Header = styled(Layout.Header)`
  background: white;
  color: white;
  border-bottom: 1px solid ${BORDER_COLOR};
  padding: 0 24px;

  a {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: larger;
  }

  .logo {
    background: transparent;
    height: 40px;
    margin-right: 16px;
    width: 80px;
  }
`;
