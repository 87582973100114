import { configureStore } from '@reduxjs/toolkit';
import accountReducer from './slices/account';
import formReducer from './slices/form';
import userReducer from './slices/user';
import studyReducer from './slices/study';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    form: formReducer,
    user: userReducer,
    study: studyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
