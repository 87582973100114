import React, { FC } from 'react';
import { Modal } from 'antd';

export interface BaseModalProps {
  onSubmit: (values?: any) => void | Promise<any>;
  onClose: () => void | Promise<any>;
  title: string;
  show: boolean;
  okText?: string;
  formName: string;
}

const withModal = (Component: FC<any>) => {
  return <T extends BaseModalProps>(props: T) => {
    const handleCancel = () => {
      if (props.onClose) {
        props.onClose();
      }
    };

    return (
      <Modal
        title={props.title}
        closable={false}
        destroyOnClose
        maskClosable={false}
        visible={props.show}
        okText={props.okText || 'Ok'}
        okButtonProps={{ form: props.formName, htmlType: 'submit' }}
        onCancel={handleCancel}
      >
        <Component {...props} />
      </Modal>
    );
  };
};

export { withModal };
